import { useState, useEffect } from "react";
const GrammarInputComponent = ({
  title,
  value,
  setValue,
  placeholder,
  submitted,
}) => {
  const [error, setError] = useState(false);


  useEffect(() => {
    if (submitted && value.trim() === "") {
      setError(true);
    }
  }, [submitted, value]);


  return (
    <div>
      <label className="text-2xl font-semibold mb-2">
        {title}
      </label>
      <input
        className={`w-full ${error ? "border-2 border-red-500": "border-b-2 focus:border-gray-400 outline-none"} rounded-lg p-2.5 mb-1.5 shadow`}
        type={"text"}
        placeholder={placeholder}
        value={value}
        onChange={(event) => setValue(event.target.value)}
      />
      {error && <p className="text-red-500 text-sm">Field is required</p>}
    </div>
  );
}

export default GrammarInputComponent;
