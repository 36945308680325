import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import QuestionBox from "../components/OtherQuestionComponents/QuestionBox";
import InputFieldComponent from "../components/OtherQuestionComponents/InputFieldComponent";
import ProgressBarComponent from "../components/OtherQuestionComponents/ProgressBarComponent";
import NextQuestionButton from "../components/OtherQuestionComponents/NextQuestionButton";
import AnswerSubmitButton from "../components/OtherQuestionComponents/AnswerSubmitButton";
import WrongFooter from "../components/OtherQuestionComponents/WrongFooter";
import CorrectFooter from "../components/OtherQuestionComponents/CorrectFooter";
import PointsFooter from "../components/OtherQuestionComponents/PointsFooter";
import api from "./../api";
import AnswerInputFieldCorrectCorrection from "../components/General/AnswerInputFieldCorrectCorrection";
import AnswerInputFieldIncorrectCorrection from "../components/General/AnswerInputFieldIncorrectCorrection";
import LoadingSpinner from "../components/General/LoadingSpinner";


const GrammarQuestions = () => {
  const [question, setQuestion] = useState([]);
  const [questionIndex, setQuestionIndex] = useState(0);
  const [userAnswer, setUserAnswer] = useState("");
  const [correction, setCorrection] = useState("");

  const [loading, setLoading] = useState(true);

  const [pointsForQuestion, setPointsForQuestion] = useState(0);
  const [points, setPoints] = useState(0);
  const [maxPoints, setMaxPoints] = useState(0);

  const [correctIncorrect, setCorrectIncorrect] = useState("");
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [showResponse, setShowResponse] = useState(false);
  const [showRecap, setShowRecap] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [previousQuestionIndex, setPreviousQuestionIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [progressBarQuestionIndex,  setProgressBarQuestionIndex] = useState(0)
  const [maxPointsUntilNow, setMaxPointsUntilNow] = useState(0)
  const [loadingAnswer, setLoadingAnswer] = useState(false);

  const { uuid } = useParams();
  const navigate = useNavigate();





  useEffect(() => {
    setLoading(true)
    api.get(`/api/grammar_questions/${uuid}/`)
    .then((response) => {
      const questionData = response.data.questions;
      console.log("\n user answer \n");
      setQuestion(questionData);
      setMaxPoints(questionData.length);
      setNumberOfQuestions(questionData.length);

      if (response.data.question_index !== 0) {
        if (response.data.question_index === questionData.length) {
          return handleRedirectToFinish();
        }
        setPreviousQuestionIndex(response.data.question_index);
        return handleContinueFromSavedPoint(response.data, response.data.question_index);
      }
      setLoading(false);
    })
    .catch((error) => {
      console.error(error);
    });
  }, [])


  const handleRedirectToFinish = () => {
    api.put("/api/grammar_reset_question_index/", {uuid: uuid})
      .then((response) => {
        navigate(`/grammar/recap/${uuid}/`);
      })
      .catch((error) => {
        console.error(error)
      })
  };

  const handleContinueFromSavedPoint = (questionData, questionIndexBefore) => {
    let sumPoints = 0
    for (let i = 0; i < questionIndexBefore; i++) {
      sumPoints += questionData.points_list[i];
    }
    setPoints(sumPoints);
    setMaxPointsUntilNow((questionIndexBefore * 3));
    setShowModal(false);
    setProgressBarQuestionIndex(questionIndexBefore);
    setQuestionIndex(questionIndexBefore);
    setLoading(false);
  }






  const handleRestart = () => {
    api.put(`/api/delete_grammar_fields/${uuid}/`, {})
    .then(() => {
      console.log("Deleted fields");
      setShowModal(false);
      setQuestionIndex(0);
    })
    .catch((error) => {
      console.log("Error while deleting fields:", error);
      setShowModal(false);
      setQuestionIndex(0);
    });
  };






  const handleShowCorrection = () => {
    setSubmitted(true);    
    if (userAnswer.trim() !== "") {
      setLoadingAnswer(true);
      console.log("handle show correction is getting called");
      const correction_data = {
        user_answer: userAnswer,
      };



      api.post("/api/grammar_answers/" + uuid + "/", correction_data)
        .then((response) => {
          api.get("/api/grammar_corrections/" + uuid + "/" + questionIndex + "/")
            .then((response) => {
              const responseData = response.data;
              console.log("this is the response data", response.data);
              setCorrection(responseData.corrections.slice(-1)[0]);
              setPointsForQuestion(responseData.points_list.slice(-1)[0]);
              setPoints((prevPoints) => prevPoints + responseData.points_list.slice(-1)[0]);
              setCorrectIncorrect(responseData.correct_incorrect.slice(-1)[0]);
              setMaxPointsUntilNow((prevMaxPoints) => prevMaxPoints + 3);
              setLoadingAnswer(false);
              setShowResponse(true);
            })
            .catch((error) => {
              console.error(error);
            });
        })
        .catch((error) => {
          console.error(error);
        });
      setSubmitted(false);
    }
  };

  const handleNextQuestion = () => {
    setQuestionIndex((prevIndex) => prevIndex + 1);
    setShowResponse(false);
    setCorrection("");
    setUserAnswer("");
  };

 
  if (loading) {
    return <LoadingSpinner />;
  };
  return (
    <div style={{ padding: "0 13vw" }}>
      <ProgressBarComponent
        index={questionIndex}
        numberOfQuestions={maxPoints}
      />


      {!showRecap && !showResponse && question.length > 0 && (
        <div>
          <QuestionBox maxPoints={1} question={question[questionIndex]} />
          <InputFieldComponent
            value={userAnswer}
            setValue={setUserAnswer}
            maxWidth="525px"
            placeholder={"Enter answer"}
            submitted={submitted}
          />
          <AnswerSubmitButton
            loadingState={loadingAnswer}
            onClick={handleShowCorrection}
            userAnswer={userAnswer}
          />
          <PointsFooter maxPoints={questionIndex} currentPoints={points} />
        </div>
      )}

      {showResponse && correctIncorrect === false && (
        <div>
          <QuestionBox maxPoints={1} question={question[questionIndex]} />

          <AnswerInputFieldIncorrectCorrection 
              userAnswer={userAnswer}
              correction={correction}
              correctionHeader={"Feedback"}
            />
          <NextQuestionButton
            questionIndex={questionIndex + 1}
            numberOfQuestions={numberOfQuestions}
            handleRedirect={handleRedirectToFinish}
            handleNextQuestion={handleNextQuestion}
          />
          <WrongFooter />
        </div>
      )}

      {showResponse && correctIncorrect === true && (
        <div>
          <QuestionBox maxPoints={1} question={question[questionIndex]} />
          <AnswerInputFieldCorrectCorrection 
            userAnswer={userAnswer}
            showHeader={true}
          />
          <NextQuestionButton
            questionIndex={questionIndex + 1}
            numberOfQuestions={numberOfQuestions}
            handleRedirect={handleRedirectToFinish}
            handleNextQuestion={handleNextQuestion}
          />
          <CorrectFooter points={pointsForQuestion} />
        </div>
      )}
    </div>
  );
};

export default GrammarQuestions;
