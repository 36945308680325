import { useState } from 'react';
import api from '../../api';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPaste } from "@fortawesome/free-solid-svg-icons";
import { notify } from '../../providers/NotificationProvider';

const GetEditAccessModal = ({ showModal, setShowModal, flashcardSetId }) => {
  const [password, setPassword] = useState("");
  const [passwordError, setPasswordError] = useState(false);
  const [passwordErrorMessage, setPasswordErrorMessage] = useState("");

  const handleSubmitPassword = () => {
    const data = {
      password: password,
      uuid: flashcardSetId
    };

    api.post("/api/flashcards/check_flashcard_editor_password/", data)
      .then((response) => {
        console.log(response.data);
        notify({message: "Edit Access Unlocked", type: "success"});
        setShowModal(false);
      })
      .catch((error) => {
        console.error(error);
        if (error.response && error.response.data.password) {
          setPasswordErrorMessage(error.response.data.password.join(" "));
        } else {
          setPasswordErrorMessage("An unexpected error occurred.");
        }
        setPasswordError(true);
      });
  };

  return showModal && (
    <div className="fixed inset-0 bg-black z-10 bg-opacity-50 flex justify-center items-center">
      <div className="bg-white rounded-xl shadow-lg max-w-xl w-full m-4">
        <div className="pt-8 px-8 pb-4">
          <div className="flex w-full justify-end">
            <FontAwesomeIcon icon={faTimes} className="text-gray-500 text-2xl hover:text-gray-600 cursor-pointer" onClick={() => setShowModal(false)} />
          </div>
          <h1 className="text-3xl font-bold">Enter Password for Edit Access</h1>
          <p className="text-lg mt-3">To access editable features of the Flashcard Set, you’ll need a password. A user with edit permissions can share this with you. Find the password in the "More Options" (···) tab</p>
        </div>
  
        <div className="mx-8 flex items-center">
          <input
            type="text"
            placeholder="••••••"
            autoFocus={true}
            onChange={(e) => {
              const re = /^[0-9\b]+$/;
              if (e.target.value === '' || re.test(e.target.value)) {
                setPasswordError(false); 
                setPassword(e.target.value.slice(0, 6));
              }
            }}
            value={password}
            maxLength={6}
            className={` p-2 rounded-lg bg-gray-50 ${passwordError ? 'border-2 border-red-500' : 'border-2 border-gray-300'}`}
            style={{ width: '120px' }} 
          />
          <button 
            className="ml-2 text-xl text-blue-600 hover:text-blue-700"
            onClick={() => {
              navigator.clipboard.readText().then(text => {
                const validText = text.replace(/[^0-9]/g, '').slice(0, 6);
                if (validText.length > 0) {
                  setPasswordError(false);
                  setPassword(validText);
                }
              });
            }}
          >
            <FontAwesomeIcon icon={faPaste} />
          </button>
        </div>
        {passwordError && <p className="text-red-500 font-medium mx-8 mt-2">{passwordErrorMessage}</p>}
  
        <div className="border-b-2 border-gray-200 mt-4" />
  
        <div className="flex justify-end space-x-4 mt-3 pb-8 px-8">
          <button
            onClick={handleSubmitPassword}
            className="px-4 py-2 bg-blue-600 text-white rounded hover:bg-blue-700 transition font-semibold"
          >
            Submit Password
          </button>
        </div>
      </div>
    </div>
  );
};

export default GetEditAccessModal;
