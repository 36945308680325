const QuestionBox = ({
  maxPoints,
  question
}) => {
  
  return (
  <div className="flex justify-center items-center">
    <div className="my-8 flex-col justify-center items-center gap-2.5 inline-flex">
      <div className="text-neutral-700 text-2xl ">{question}</div>
      <div className="text-sm font-medium text-gray-600">Max Points: {maxPoints}</div>
    </div>
  </div>
  )

}
export default QuestionBox;