const TextIcon = (props) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlSpace="preserve"
    width={512}
    height={512}
    fillRule="evenodd"
    style={{
      enableBackground: "new 0 0 512 512",
    }}
    viewBox="0 0 24 24"
    {...props}
  >
    <path
      fill="currentColor"
      stroke="currentColor"
      strokeWidth={1.5}
      d="M13 19h2a1 1 0 0 1 0 2H9a1 1 0 0 1 0-2h2V5H5v2a1 1 0 0 1-2 0V4a1 1 0 0 1 1-1h16a1 1 0 0 1 1 1v3a1 1 0 0 1-2 0V5h-6z"
      data-original="#000000"
    />
  </svg>
)
export default TextIcon;