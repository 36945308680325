const ShortAnswerRecap = ({
  question,
  correctIncorrect,
  answer,
  point,
  correction,
  type
}) => {
  return (
    <div>
      <p className="text-xl mb-3">{question}</p>
      {type !== "flashcard" && <p className="text-gray-500 text-lg">Points: {point}</p>}
      {correctIncorrect ? (
        <div>
          <label className="text-green-600 font-bold my-2 text-center">Your Answer:</label>
          <p className="bg-green-100 border-2 border-green-300 rounded-md mb-2 p-3">
            {answer}
          </p>

        </div>
      ) : (
        <div>
          <label className="text-red-600 font-bold my-2 text-center">Your Answer:</label>
          <p className="border-2 border-red-400 rounded-md p-3 mb-2">
            {answer}
          </p>
          <label className="text-green-600 font-bold my-2">Correction:</label>
          <p className="bg-green-100 border-2 border-green-300 rounded-md p-3 mb-2">
            {correction}
          </p>
          
        </div>
      )}
    </div>
  );
};

export default ShortAnswerRecap;
