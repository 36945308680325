import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faStar, faPen, faPlus } from "@fortawesome/free-solid-svg-icons";
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import api from "./../api";

const FlashcardEditField = ({
  flashcards,
  setFlashcards,
  flashcardSetUuid,
  flashcardLimit,
  setShowLimitModal
}) => {
  const [showMaxFieldsMessage, setShowMaxFieldsMessage] = useState(false);
  const [disableAddButton, setDisableAddButton] = useState(false);
  const [addFieldActive, setAddFieldActive] = useState(false);
  const [editFlashcardIndex, setEditFlashcardIndex] = useState(null);
  const [editingFlashcard, setEditingFlashcard] = useState({});
  const [flashcardErrorIndex, setFlashcardErrorIndex] = useState(null);



  const handleEditFlashcards = (index) => {
    if (editFlashcardIndex !== null) {
      handleCancel();
    } else {
      setDisableAddButton(true);
      setEditFlashcardIndex(index);
      setEditingFlashcard(flashcards[index]);
    }
  }



  const handleSaveEditedFlashcards = () => {
    if (editingFlashcard.word.trim() === "" || editingFlashcard.definition.trim() === "") {
      setFlashcardErrorIndex(editFlashcardIndex);
      setDisableAddButton(true);
    } else {
      if (addFieldActive===false) {
        const newFlashcards = [...flashcards];
        newFlashcards[editFlashcardIndex] = editingFlashcard;
        setFlashcards(newFlashcards);

        const data = {
          flashcard: editingFlashcard,
          flashcard_set_uuid: flashcardSetUuid
        }

        api.put("/api/flashcards/update_flashcard/", data)
        .then((response) => {
          console.log("this is the response from the backend", response.data);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });
        setFlashcardErrorIndex(null);
        setEditFlashcardIndex(null);
        setEditingFlashcard({});
        setAddFieldActive(false)
        setDisableAddButton(false)
      }
      else {
        const data = {
          flashcard_set_uuid: flashcardSetUuid,
          flashcard: {
            word: editingFlashcard.word,
            definition: editingFlashcard.definition,
            position: flashcards.length,
          }
        }
        console.log("data: ", data);


        api.post("/api/flashcards/add_flashcard/", data)
        .then((response) => {
          const newFlashcard = response.data;
          setFlashcards((prevFlashcards) => [...prevFlashcards.slice(0, prevFlashcards.length - 1),newFlashcard]);
        })
        .catch((error) => {
          console.error("There was an error!", error);
        });

        setFlashcardErrorIndex(null);
        setEditFlashcardIndex(null);
        setEditingFlashcard({});
        setAddFieldActive(false)
        setDisableAddButton(false)
      }
    }
  };


  const handleAddFlashcard = () => {
    if (flashcards.length < flashcardLimit && !showMaxFieldsMessage) {
      setDisableAddButton(true);
      setFlashcards([...flashcards, {word: "", definition: ""}]);
      setAddFieldActive(true);
      setEditFlashcardIndex(flashcards.length);
    } else {
      setShowLimitModal(true);
      setShowMaxFieldsMessage(true);
      setDisableAddButton(true);
    }
  }

  const handleCancel = () => {
    if (addFieldActive){
      setFlashcards((prevFlashcards) => prevFlashcards.slice(0, prevFlashcards.length - 1));
      setEditFlashcardIndex(null);
      setEditingFlashcard({});
      setFlashcardErrorIndex(null);
      setDisableAddButton(false);
      setAddFieldActive(false);
    }
    else {
      setEditFlashcardIndex(null);
      setEditingFlashcard({});
      setFlashcardErrorIndex(null);
      setDisableAddButton(false);
    }
  }




  const toggleStar = (index) => {
    console.log("this is the flashcarddata from the toggled star", flashcards[index]);
    setFlashcards((prevFlashcards) => {
      const newFlashcards = [...prevFlashcards];
      newFlashcards[index] = {
        ...newFlashcards[index],
        starred: !newFlashcards[index].starred,
      };
      return newFlashcards;
    }
    );
    api.put("/api/flashcards/update_starred/", {starred: !flashcards[index].starred, uuid: flashcards[index].uuid})
    
  }
  

  const handleDeleteFlashcard = (indexToRemove) => {
    console.log("this is the index that should get removed", indexToRemove)
    if (flashcards.length > 1) {
      api.delete("/api/flashcards/delete_flashcard/"+flashcards[indexToRemove].uuid+"/"+flashcardSetUuid+"/")
      .then((response) => {
        console.log("this is the response from the backend", response.data);
        const newFlashcards = [...flashcards];
        newFlashcards.splice(indexToRemove, 1);
        setFlashcards(newFlashcards);
        setEditFlashcardIndex(null);
        setEditingFlashcard({});
        if (showMaxFieldsMessage && flashcards.length < 20) {
          setShowMaxFieldsMessage(false);
        }
      }
      )
      .catch((error) => {
        console.error("There was an error!", error);
      }
      );
    }
  }
  
  console.log("this is the edit flashcard index", editFlashcardIndex);
  return (
    <div className="mb-16">
      {flashcards.map((flashcard, index) => (
        <div key={index} className="bg-white rounded-2xl shadow p-4 mb-4" onClick={() => handleEditFlashcards(index)}>
          <div className="flex-grow mb-2">
            <div className="flex flex-row justify-between items-center">
              <div className="text-lg font-bold mb-2">{index + 1}</div>
              <div className="flex gap-6">

                {editFlashcardIndex !== index ? (
                  <>
                    <button onClick={(e) => {
                      e.stopPropagation();
                      toggleStar(index);
                    }} className="mb-2 outline-none">
                      {flashcard.starred ? (
                        <FontAwesomeIcon icon={faStar} className="text-yellow-400" style={{ fontSize: "19px" }} />
                      ) : (
                        <FontAwesomeIcon icon={farStar} className="text-gray-500" style={{ fontSize: "19px" }} />
                      )}
                    </button>
                    <FontAwesomeIcon icon={faPen} className="text-blue-500 hover:text-blue-600" style={{ fontSize: "18px" }} />
                  </>
                ) : addFieldActive === false && (
                  <button onClick={(e) => {
                    e.stopPropagation();
                    handleDeleteFlashcard(index);
                  }} className="mb-2 outline-none">
                    <FontAwesomeIcon icon={faTrash} className="text-red-500" style={{ fontSize: "17px" }}/>
                  </button>
                )}

              </div>
            </div>

          {editFlashcardIndex !== index ? (
            <div className="flex flex-col">
              <div className="flex flex-col md:flex-row justify-between gap-2">
                <div className="text-lg overflow-hidden whitespace-normal break-words w-full md:w-1/2 md:mr-2">
                  {flashcard.word}
                </div>
                <div className="h-0.5 visible md:hidden"/>
                <div className="h-0.5 visible md:hidden rounded-lg bg-gray-200"/>
                <div className="h-0.5 visible md:hidden"/>
                <div className="text-lg overflow-hidden whitespace-normal break-words w-full md:w-1/2 md:ml-2">
                  {flashcard.definition}
                </div>
              </div>
            </div>
            ) : (
              <div className="flex flex-col gap-2">
                <div className="flex flex-col md:flex-row justify-between">
                  <textarea 
                    className={`text-lg break-words w-full md:w-1/2 md:mr-2 bg-gray-50 p-2.5 rounded-lg resize-none focus:outline-none ${flashcardErrorIndex === index && editingFlashcard.word.trim() === "" ? "border-2 border-red-500" :"border border-gray-300"}`} 
                    value={editingFlashcard.word} 
                    onChange={(e) => setEditingFlashcard({ ...editingFlashcard, word: e.target.value })} 
                    onClick={(e) => e.stopPropagation()} 
                  />
                  <div className="h-1.5 visible md:hidden" />
                  <textarea 
                    className={`text-lg break-words w-full md:w-1/2 md:ml-2 bg-gray-50 p-2.5 rounded-lg resize-none focus:outline-none ${flashcardErrorIndex === index && editingFlashcard.definition.trim() === "" ? "border-2 border-red-500" :"border border-gray-300"}`} 
                    value={editingFlashcard.definition} 
                    onChange={(e) => setEditingFlashcard({...editingFlashcard, definition: e.target.value })} 
                    onClick={(e) => e.stopPropagation()} 
                  />
                </div>
              </div>
            )}
            {flashcardErrorIndex === index && editingFlashcard.word.trim() === "" && (
              <p className="text-red-500 text-sm">Word is required</p>
            )}
            {flashcardErrorIndex === index && editingFlashcard.definition.trim() === "" && (
              <p className="text-red-500 text-sm">Definition is required</p>
            )}
          </div>
          {editFlashcardIndex === index && (
            <div>
              <button
                className="rounded-lg transition w-full bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-60 mb-2"
                onClick={(e) => {
                  e.stopPropagation();
                  handleSaveEditedFlashcards();
                }}
              >
                {addFieldActive ? "Save new word" : "Save Change"}
              </button> 
              <button
                className="hover:bg-neutral-100 rounded-lg transition w-full text-center  text-blue-600 px-3.5 py-2.5 text-sm font-semibold"
                onClick={(e) => {
                  e.stopPropagation();
                  handleCancel();
                }}
              >
                Cancel
              </button>
            </div>
          )}
        </div>
      ))}
      <div className="flex justify-center mt-16 w-full">
        <button
          className={`${disableAddButton === true ? "text-gray-500" : "text-white"} bg-blue-600 border-2 border-blue-600 py-2.5 px-8 w-full rounded-lg font-medium text-lg transition-colors duration-300 ease-in-out
                    hover:bg-blue-700 hover:border-blue-600
                    active:bg-blue-800 active:border-blue-700 active:shadow-inner
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                    shadow-color-lg shadow-blue-400
                    disabled:bg-gray-200 disabled:border-gray-300 disabled:shadow-gray-300 disabled:cursor-not-allowed disabled:text-gray-500`}
          onClick={handleAddFlashcard}
          disabled={disableAddButton}
        >
          Add a term
        </button>
      </div>
      
      {showMaxFieldsMessage && (
        <div className="text-center mt-4 flex justify-center">
          <p className="text-lg font-semibold text-red-500 inline">
            You've reached the maximum field limit ({flashcardLimit}).
          </p>
          <a href="/pricing" className="text-lg font-semibold underline text-blue-600 ml-2 hover:text-blue-800">Upgrade</a>
        </div>
      )}

    </div>
  );

};

export default FlashcardEditField;




// This is the code before changing the edit mode thing:

// import { useEffect, useState } from "react";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash, faStar, faPen, faCoins } from "@fortawesome/free-solid-svg-icons";
// import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
// import api from "api";
// import Cookies from "js-cookie";

// const FlashcardEditField = ({
//   words,
//   setWords,
//   handleSaveChanges,
//   handleSaveDeletedField,
//   flashcards,
//   setFlashcards,
// }) => {
//   const [showMaxFieldsMessage, setShowMaxFieldsMessage] = useState(false);
//   const [disableAddButton, setDisableAddButton] = useState(false);
//   const [editFieldIndex, setEditFieldIndex] = useState(null);
//   const [editedWord, setEditedWord] = useState("");
//   const [addFieldActive, setAddFieldActive] = useState(false);
//   const [wordErrorIndex, setWordErrorIndex] = useState(null);


//   const [isStarClicked, setIsStarClicked] = useState(false);

//   console.log("these are the flashcard i get", flashcards);
//   const headers = {Authorization: `Token ${Cookies.get("authToken")}`,};




//   const handleEditClick = (index) => {
//     setDisableAddButton(true);
//     setEditFieldIndex(index);
//     setEditedWord(words[index]);
//     console.log("Edit clicked");
//   };

//   const handleWordChange = () => {
//     console.log("Thsi is the edited word", editedWord);

//     if (editedWord.trim() === "") {
//       console.log("word is empty");
//       setWordErrorIndex(editFieldIndex);
//       setDisableAddButton(true);
//     } else {
//       console.log("word is not empty");

//       const newWords = [...words];
//       newWords[editFieldIndex] = editedWord;
//       setWords(newWords);
//       handleSaveChanges(newWords);
//       setWordErrorIndex(null);
//       setEditFieldIndex(null);
//       setEditedWord("");
//       setAddFieldActive(false);
//       setDisableAddButton(false);
//     }
//   };

//   const handleAddField = () => {
//     if (words.length < 20 && !showMaxFieldsMessage) {
//       setDisableAddButton(true);
//       setWords([...words, ""]);
//       setAddFieldActive(true);
//       setEditFieldIndex(words.length);
//     } else {
//       setShowMaxFieldsMessage(true);
//       setDisableAddButton(true);
//     }
//   };

//   const handleCancle = () => {
//     if (addFieldActive) {
//       const newWords = [...words];
//       newWords.splice(editFieldIndex, 1);
//       setWords(newWords);
//       setEditFieldIndex(null);
//       setEditedWord("");
//       setAddFieldActive(false);
//       setDisableAddButton(false);
//       setWordErrorIndex(null);
//     } else {
//       setEditFieldIndex(null);
//       setEditedWord("");
//       setWordErrorIndex(null);
//       setDisableAddButton(false);
//     }
//   };

//   const toggleStar = (index) => {
//     console.log("this is the flashcarddata from the toggled star", flashcards[index]);
//     setFlashcards((prevFlashcards) => {
//       const newFlashcards = [...prevFlashcards];
//       newFlashcards[index] = {
//         ...newFlashcards[index],
//         starred: !newFlashcards[index].starred,
//       };
//       return newFlashcards;
//     }
//     );
//     api.put("/api/flashcards/update_starred/", {starred: !flashcards[index].starred, uuid: flashcards[index].uuid})
    
//   }

//   console.log("these are the flashcards", flashcards);
  



//   const handleDeleteField = (indexToRemove) => {
//     console.log("save delted field is getting called");

//     if (words.length > 1) {
   
      

//       handleSaveDeletedField(indexToRemove);
//       setEditFieldIndex(null);
//       setEditedWord("");
//       if (showMaxFieldsMessage && words.length < 20) {
//         setShowMaxFieldsMessage(false);
//       }
//     }
//   };

//   return (
//     <div>
//       {flashcards.map((flashcard, index) => (
//         <div key={index} className="bg-white rounded-2xl shadow p-4 mb-4">
//           <div className="flex-grow mb-4">
//             <div className="flex flex-row justify-between items-center">
//               <div className="text-lg font-bold mb-2">{index + 1}</div>
//               <div className="flex gap-2">
//               <button onClick={(e) => {e.stopPropagation(); toggleStar(index);}} className="mb-2 outline-none">
//                 {flashcard.starred ? (
//                   <FontAwesomeIcon icon={faStar} className="text-yellow-400" style={{ fontSize: "18px" }}/>
//                 ) : (
//                   <FontAwesomeIcon icon={farStar} className="text-gray-600" style={{ fontSize: "18px" }} />
//                 )}
//               </button>
//               </div>
//             </div>

//           {editFieldIndex !== index ? (
//             <div className="flex flex-col gap-2">
//               <div className="flex flex-col sm:flex-row justify-between">
//                 <div className="text-xl overflow-hidden whitespace-normal break-words w-full sm:w-1/2">
//                   {flashcard.word}
//                 </div>
//                 <div className="text-xl overflow-hidden whitespace-normal break-words w-full sm:w-1/2">
//                   {flashcard.definition}
//                 </div>
//               </div>
//               <button className="flex items-center justify-end w-full mt-2" onClick={() => handleEditClick(index)} disabled={wordErrorIndex !== null}>
//                 <span className="mr-2 text-blue-600">Edit</span>
//                 <FontAwesomeIcon icon={faPen} style={{ fontSize: "18px" }} color="#2563EB"/>
//               </button>
//             </div>
//             ) : (
//               <div className="flex items-center justify-between">
//                 <input
//                   type="text"
//                   className={`p-2.5 flex-grow ${
//                     wordErrorIndex === index && editedWord.trim() === ""
//                       ? "border-red-500 border-2"
//                       : "border-1 border-gray-300"
//                   } rounded-lg focus:none`}
//                   value={editedWord}
//                   onChange={(event) => setEditedWord(event.target.value)}
//                 />

//                 {!addFieldActive && (
//                   <button
//                     className="flex items-center ml-4"
//                     onClick={() => handleDeleteField(index)}
//                   >
//                     <FontAwesomeIcon
//                       icon={faTrash}
//                       style={{ fontSize: "18px" }}
//                       color="#DC2626"
//                     />
//                   </button>
//                 )}
//               </div>
//             )}
//             {wordErrorIndex === index && editedWord.trim() === "" && (
//               <p className="text-red-500 text-sm">Word is required</p>
//             )}
//           </div>
//           {editFieldIndex === index && (
//             <div>
//               <button
//                 className="rounded-lg transition w-full bg-blue-600 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-60"
//                 onClick={handleWordChange}
//               >
//                 {addFieldActive ? "Save new word" : "Save Change"}
//               </button>
//               <button
//                 className="hover:bg-neutral-100 rounded-lg transition w-full text-center  text-blue-600 px-3.5 py-2.5 text-sm font-semibold"
//                 onClick={handleCancle}
//               >
//                 Cancel
//               </button>
//             </div>
//           )}
//         </div>
//       ))}
//       <div className="flex justify-center">
//         <button
//           type="button"
//           className="mt-3 flex-grow w-full border-2 border-gray-300 p-2.5 rounded-lg hover:bg-blue-100 hover:border-blue-500 active:bg-blue-100 active:border-blue-500"
//           onClick={handleAddField}
//           disabled={disableAddButton}
//         >
//           Add a word
//         </button>
//       </div>
//       <div className="mt-3 flex justify-center">
//         {showMaxFieldsMessage && (
//           <p style={{ color: "red", marginTop: "25px" }}>
//             You've reached the maximum field limit (20).
//           </p>
//         )}
//       </div>
//     </div>
//   );
// };

// export default FlashcardEditField;
