import React, { useEffect, useState } from 'react';

const ProgressBarCircle = ({ percent }) => {
  const [strokeWidth, setStrokeWidth] = useState(4); // Fine-tuned stroke width
  const [fontSize, setFontSize] = useState('8'); // Optimized font size for readability
  const [baseSizeMultiplier, setBaseSizeMultiplier] = useState(1);

  useEffect(() => {
    const updateSize = () => {
      if (window.innerWidth < 640) {
        setStrokeWidth(7); // Consistent for smaller screens
        setFontSize('16'); // Slightly smaller font for small screens
        setBaseSizeMultiplier(1); // Adjusted for smaller screens
      } else if (window.innerWidth >= 640 && window.innerWidth < 1024) {
        setStrokeWidth(8); // Adjusted for medium screens
        setFontSize('12'); // Suitable font size for medium screens
        setBaseSizeMultiplier(2); // Default size for medium screens
      } else {
        setStrokeWidth(9); // Adjusted for larger screens
        setFontSize('12'); // Slightly larger font for large screens
        setBaseSizeMultiplier(3); // Adjusted for larger screens
      }
    };

    // Update dimensions on resize
    window.addEventListener('resize', updateSize);

    // Set initial size
    updateSize();

    // Cleanup listener
    return () => window.removeEventListener('resize', updateSize);
  }, []);

  const baseSize = 100; // Base size used for scaling
  const radius = (baseSize / 2) - strokeWidth; // Adjust radius based on stroke width to fit within the SVG
  const circumference = 2 * Math.PI * radius;
  const strokeDashoffset = circumference - (percent / 100) * circumference;

  // Calculate the dynamic size of the SVG container based on the baseSize
  const svgSize = `${baseSize * baseSizeMultiplier}px`;

  return (
    <div className="relative flex justify-center items-center w-full h-full">
      <svg
        viewBox={`0 0 ${baseSize} ${baseSize}`}
        // Apply the dynamic size to both width and height
        style={{ width: svgSize, height: svgSize }}
      >
        {/* Background circle */}
        <circle
          className="stroke-current text-[#ff9d00]"
          strokeWidth={strokeWidth}
          cx={baseSize / 2}
          cy={baseSize / 2}
          r={radius}
          fill="transparent"
        />

        {/* Progress circle */}
        <circle
          className="stroke-current text-[#4ade80]"
          strokeWidth={strokeWidth}
          strokeLinecap="round"
          transform={`rotate(-90 ${baseSize / 2} ${baseSize / 2})`}
          cx={baseSize / 2}
          cy={baseSize / 2}
          r={radius}
          fill="transparent"
          strokeDasharray={circumference}
          strokeDashoffset={strokeDashoffset}
        />
        
        {/* Center text, adjusted for visibility */}
        <text
          x="50%"
          y="50%"
          dy=".3em" // Vertically centers the text
          fontSize={fontSize} // Dynamically adjusted font size
          textAnchor="middle"
          fontWeight="500" // Medium bold weight
        >
          {percent}%
        </text>


      </svg>
    </div>
  );
};

export default ProgressBarCircle;
