import { useNavigate } from "react-router-dom";
import { useState } from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileLines } from '@fortawesome/free-solid-svg-icons';
import pdfToText from 'react-pdftotext'

const ExamUploadDocument = ({ userCharacterLimit, setFile, setFileText, fileUploadErrors, setFileUploadErrors, setShowLimitModal, setLimitReason }) => {
  const [dragOver, setDragOver] = useState(false);

  const [fileSubmitted, setFileSubmitted] = useState(false);
  const [fileName, setFileName] = useState("");
  const [showUpgradeButton, setShowUpgradeButton] = useState(false);

  const navigate = useNavigate();



  const handleDragEnter = (e) => {
    e.preventDefault();
    e.stopPropagation();
    setDragOver(true);
  };

  const handleDragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);
  };

  const handleDragOver = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(true);
  };

  const handleDrop = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setDragOver(false);
      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        processFile(e.dataTransfer.files);
        e.dataTransfer.clearData();
      }
  };
  const processFile = (file) => {
    // Info: 5242880 = 5MB
    if (file.size > 5242880) {
      return setFileUploadErrors("File size is too large. Please upload a file less than 5MB.");
    } 
    console.log("File type: ", file[0].type)

    if (file[0].type === "application/pdf") {
      pdfToText(file[0])
      .then((text) => {
        console.log("Uploaded text", text)
        console.log("Uploaded text length", text.length)
        if (text === "") {
          setFileUploadErrors("File is empty. No content could get extracted. Please upload a file with content.");
          return;
        } else if (text.length > userCharacterLimit) {
          setFileUploadErrors(`File is too large, upload a file with less than ${userCharacterLimit.toLocaleString()} characters.`);
          setShowUpgradeButton(true);
          setLimitReason("text");
          setShowLimitModal(true);
        } else {
          setFileText(text);
          setFileUploadErrors("");
          setFileSubmitted(true);
          setShowLimitModal(false);
        }

      })
      .catch((error) => {
        console.error("Failed to extract text from pdf", error)
        setFileUploadErrors("Failed to extract text from pdf. Please upload a file with content.");
        setFileText("");
      });
    } else {
      setFileUploadErrors("");
      setFileSubmitted(true);
      setFile(file[0]);
    }
    setFileName(file[0].name);
  };


  



  // TODO: change here that the loading should be on the main page and also the uplaod should also be on the main page







  return (
    <div>
      <div className="">

        <div className={`h-[400px] border-4 border-dashed rounded-lg ${dragOver ? 'border-blue-600 bg-blue-200' : 'border-blue-400 bg-blue-100'} relative`}
          onDragEnter={handleDragEnter}
          onDragOver={handleDragOver}
          onDragLeave={handleDragLeave}
          onDrop={handleDrop}
        >
          {!fileSubmitted && (
            <input type="file" id="file-upload" className="opacity-0 w-full h-full absolute inset-0 cursor-pointer"
              onChange={(e) => processFile(e.target.files)}
              accept=".pdf,.docx"
              onClick={(event) => { event.target.value = null; }}
              multiple={false}
            />
          )}
          <label htmlFor="file-upload" className={`text-center flex flex-col items-center justify-center h-full `}>
            {fileSubmitted ? (
              <div>
                <FontAwesomeIcon icon={faFileLines} className='text-blue-500 text-9xl' />
                <p className='text-xl mt-5 mb-8  text-blue-600 font-medium'>{fileName}</p>
                <button className='text-lg bg-blue-200 text-blue-600 rounded-full px-3 py-1.5 font-semibold hover:bg-blue-300 hover:text-blue-700' onClick={() => setFileSubmitted(false)}>
                  Change File
                </button>
              </div>
            ) : (
              <div>
                <p className="text-blue-600 font-medium text-lg">Drag and drop your files here or click to select files.</p>
                <p className="text-blue-600 font-medium text-lg">Only PDF and DOCX files are allowed.</p>
              </div>
            )}
          </label>
        </div>
      </div>

      <div className="flex flex-row items-center  mt-3">
        <p className='text-lg font-medium text-red-500 mr-1'>{fileUploadErrors}</p>
        {showUpgradeButton && <button className="text-lg font-medium text-blue-600 underline-offset-1 underline" onClick={() => navigate("/pricing/")} >Upgrade to Study Buddy MAX!</button>}
      </div>
      
    </div>
  )

};

export default ExamUploadDocument;