import { useEffect, useState } from "react";
import { useParams, useNavigate } from "react-router-dom";
import PointsBarComponent from "../components/QuestionRecap/PointsBarComponent";
import ShortAnswerRecap from "../components/QuestionRecap/ShortAnswerRecapComponent";
import LoadingSpinner from "../components/General/LoadingSpinner";
import api from "../api";
import AnswerInputFieldCorrectCorrection from "../components/General/AnswerInputFieldCorrectCorrection";
import AnswerInputFieldIncorrectCorrection from "../components/General/AnswerInputFieldIncorrectCorrection";

const FlashcardRecap = () => {
  const [maxPoints, setMaxPoints] = useState(0);
  const [points, setPoints] = useState(0);
  const [allCorrect, setAllCorrect] = useState(false);
  const [flashcards, setFlashcards] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { uuid, type } = useParams();


  const askTermsFormatter = (flashcardInput) => {
    return flashcardInput.map(flashcard => ({
      uuid: flashcard.uuid,
      question: flashcard.definition,
      answer: flashcard.user_answer,
      correctIncorrect: flashcard.correct_incorrect[flashcard.correct_incorrect.length - 1],
      correction: flashcard.correction,
      point: flashcard.points
    }));
  }
  const askDefinitionsFormatter = (flashcardInput) => {
    return flashcardInput.map(flashcard => ({
      uuid: flashcard.uuid,
      question: flashcard.word,
      answer: flashcard.user_answer,
      correctIncorrect: flashcard.correct_incorrect[flashcard.correct_incorrect.length - 1],
      correction: flashcard.correction,
      point: flashcard.points
    }));
  }

  useEffect(() => {
    setLoading(true);
    const urls = [`/api/flashcards/write_mode_settings/${uuid}/`, `/api/flashcards/saved_write_mode_flashcards/${uuid}/`];

    Promise.all(urls.map(url => api.get(url)))
    .then((responses) => {
      const settingsResponse = responses[0].data;
      const flashcardsResponse = responses[1].data;

      console.log("this is the flashcard data: ", responses[1].data)

      let flashcardData = [];
      if (settingsResponse.ask_definition) {
        flashcardData = askDefinitionsFormatter(flashcardsResponse);
      }
      else {
        flashcardData = askTermsFormatter(flashcardsResponse);
      }
      setAllCorrect(flashcardData.every(item => { return item.correctIncorrect === true; }));
      setPoints(flashcardData.reduce((acc, item) => acc + item.point, 0));
      setMaxPoints(flashcardData.length);
      setFlashcards(flashcardData);
      setLoading(false);
    })
    .catch((error) => {
      console.error("Failed to fetch data:", error);
    });
  }, []);






  const handleRepeatButtonClicked = () => {
    if (allCorrect) {
      navigate(`/flashcard/preview/${uuid}/`);
    }
    else {
      api.put("/api/flashcards/update_flashcard_mode_index/", { uuid : uuid, index:0, question_type: "incorrect"})
      .then((response) => {
        if (type === "starred" || type === "starred-incorrect") {
          navigate(`/flashcard/mode/write/${uuid}/starred-incorrect/`);
        } else if (type === "all" || type === "incorrect") {
          navigate(`/flashcard/mode/write/${uuid}/incorrect/`);
        }
      })
      .catch((error) => {
        console.error("Failed to update flashcard mode index:", error);
      });
    }
  }




  // const handleButtonClicked = () => {
  //   if (allCorrect) {
  //     navigate("/flashcard/all/")
  //   }
  //   else {
  //     navigate("/flashcard/mode/write/" + uuid + "/incorrect/")
  //   }
  // };





  if (loading) {
    return (
      <LoadingSpinner />
    );
  }
  return (
    <div style={{ backgroundColor: "#F6F7FB" }} className="px-6 min-h-screen">
      <PointsBarComponent points={points} maxPoints={maxPoints} handleExitButtonClicked={() => navigate(`/flashcard/preview/${uuid}/`)} handleRepeatButtonClicked={handleRepeatButtonClicked} />
      {flashcards.map((flashcard, index) => (
        <div className="flex justify-center items-center" key={index}>
          <div 
            className={`max-w-[735px] w-full bg-white rounded-2xl mb-8 p-8 shadow-color-lg ${flashcard.correctIncorrect ? "shadow-green-300" : "shadow-red-300"}`}
          >
            <p className="text-xl mb-4">{flashcard.question}</p>
            {flashcard.correctIncorrect ? (
              <AnswerInputFieldCorrectCorrection userAnswer={flashcard.answer} showHeader={false} />
            ) : (
              <AnswerInputFieldIncorrectCorrection userAnswer={flashcard.answer} correction={flashcard.correction} correctionHeader={"Correct answer"} />
            )}

          </div>
        </div>
      ))}
      <div className="flex justify-center pb-20 mt-4">
        <button
          className={`text-white bg-blue-600 border-2 border-blue-600 py-2.5 px-20 rounded-lg font-medium text-lg transition-colors duration-300 ease-in-out
                    hover:bg-blue-700 hover:border-blue-600
                    active:bg-blue-800 active:border-blue-700 active:shadow-inner
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                    shadow-color-lg shadow-blue-400
                    disabled:bg-gray-200 disabled:border-gray-300 disabled:shadow-gray-300 disabled:cursor-not-allowed disabled:text-gray-500`}
          onClick={handleRepeatButtonClicked}
        >
          {allCorrect ? "Repeat all terms" : "Repeat wrong terms"}
        </button>
      </div>
    </div>

  );
};
export default FlashcardRecap;


//OLD button code:
{/* <div className="flex flex-col items-center mt-8">
<button
  type="button"
  className="text-white bg-gradient-to-r from-cyan-400 via-cyan-500 to-cyan-600 hover:bg-gradient-to-br focus:ring-4 focus:outline-none focus:ring-cyan-300 dark:focus:ring-cyan-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2"
  onClick={handleRepeatButtonClicked}
>
  {allCorrect ? "Repeat all words" : "Repeat wrong words"}
</button>
</div> */}




// OLD CODE:
// {flashcards.map((flashcard, index) => (
//   <div className="flex justify-center items-center">
//     <div className="max-w-[735px] w-full">
//       <div className="bg-white rounded-lg shadow-md mb-4">
//         <div className="px-8 pb-12 pt-8">
//           <ShortAnswerRecap
//             key={index}
//             question={flashcard.question}
//             correctIncorrect={flashcard.correctIncorrect}
//             answer={flashcard.answer}
//             point={flashcard.point}
//             correction={flashcard.correction}
//             type={"flashcard"}
//           />
//         </div>
//       </div>
//     </div>
//   </div>
// ))}