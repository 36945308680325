import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"; 
import { faTimes, faRepeat } from "@fortawesome/free-solid-svg-icons";

const PointsBarComponent = ({ points, maxPoints, handleRepeatButtonClicked, handleExitButtonClicked }) => (
  <div className="max-w-[735px] mx-auto">
    <div className="flex items-center justify-between pt-6 mb-2.5">
      <button className="w-6" onClick={handleExitButtonClicked}>
        <FontAwesomeIcon icon={faTimes} className="text-gray-500 hover:text-gray-600 text-3xl" />
      </button>
      <span className="text-xl font-semibold text-zinc-700">
        Points: {points}/{maxPoints} - {Math.round((points / maxPoints) * 100)}%
      </span>
      <button className="w-6" onClick={handleRepeatButtonClicked}>
        <FontAwesomeIcon icon={faRepeat} className="text-gray-500 hover:text-gray-600 text-2xl" />
      </button>
    </div>
    <div className="flex-grow h-7 rounded-full overflow-hidden bg-red-400 mb-10">
      <div
        style={{ width: `${(points / maxPoints) * 100}%` }}
        className="h-7 rounded-full bg-green-400"
      ></div>
    </div>
  </div>
);


export default PointsBarComponent;
