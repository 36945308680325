import { useState } from "react";
import { Navigate } from "react-router-dom";
import "reactjs-popup/dist/index.css";
import TitleInput from "../components/InputComponents/TitleInput";
import GrammarInputComponent from "../components/Grammar/GrammarInputComponent";
import NotEnoughTokensModal from "../components/OtherQuestionComponents/NotEnoughTokensModal";
import api from "./../api";
import Lottie from "react-lottie";
import animationData from "../Assets/Lottie/generate-from-file.json";


const GrammarInput = () => {
  const [language, setLanguage] = useState("");
  const [grammarTopic, setGrammarTopic] = useState("");
  const [exampleExercise, setExampleExercise] = useState("");
  const [numberOfExercises, setNumberOfExercises] = useState(1);
  const [title, setTitle] = useState("");
  const [redirectToQuestions, setRedirectToQuestions] = useState(false);
  const [notEnoughTokens, setNotEnoughTokens] = useState(false); // Add loading state
  const [tokens, setTokens] = useState(0); // Add loading state
  const [uuid, setUuid] = useState("");
  const [difficulty, setDifficulty] = useState("medium");
  const [submitted, setSubmitted] = useState(false);
  const [loading, setLoading] = useState(false);
  const [generating, setGenerating] = useState(false);


  if (redirectToQuestions) {
    const url = `/grammar/questions/${uuid}/`;
    return <Navigate to={url} />;
  }

  const handleSubmit = () => {
    setSubmitted(true);
    if (
      language !== "" &&
      grammarTopic !== "" &&
      exampleExercise !== "" &&
      numberOfExercises !== ""
    ) {
      setLoading(true);
      const data = {
        language: language,
        topic: grammarTopic,
        example: exampleExercise,
        number_of_exercises: numberOfExercises,
        title: title,
        difficulty: difficulty,
      };
      console.log(
        "this is the data format I want to save in the database",
        data
      );
      console.log("SENDING A POST REQUEST TO THE BACKEND");
      api.post("/api/grammar_input/", data)
        .then((response) => {
          console.log(response.data); // Optional: log the response
          const enough_tokens = response.data.enough_tokens;
          console.log(enough_tokens);

          setTokens(response.data.tokens);
          if (enough_tokens==="false" || enough_tokens===false) {
            setNotEnoughTokens(true);
          } else {
            const uuid = response.data.uuid;
            setUuid(uuid);
            console.log("uuid", uuid);
            setRedirectToQuestions(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  };



  const submitIsValid = () => {
    return (
      language !== "" &&
      grammarTopic !== "" &&
      exampleExercise !== "" &&
      numberOfExercises !== ""
    );
  };


  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  if (generating) {
    return (
      <div className='h-[600px] flex flex-col items-center justify-center min-h-screen max-h-screen' style={{ background: "#F6F7FB" }}>
        <Lottie 
          options={defaultOptions}
          height={550}
          width={550}
        />
        <h1 className='text-2xl font-semibold text-purple-500'>Generating Exam...</h1>
      </div>
    );
  }


  return (
    <div className="conatainer mx-auto min-h-screen" style={{ padding: "0 13vw", backgroundColor: "#F6F7FB" }}>
      <NotEnoughTokensModal showModal={notEnoughTokens} setShowModal={setNotEnoughTokens } tokensMissing={tokens} />

      <TitleInput
        value={title}
        setValue={setTitle}
        submitted={submitted}
      />

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <GrammarInputComponent
          title={"Language:"}
          value={language}
          setValue={setLanguage}
          placeholder={"Language"}
          submitted={submitted}
        />
        <GrammarInputComponent
          title={"Grammar topic:"}
          value={grammarTopic}
          setValue={setGrammarTopic}
          placeholder={"Grammar topic"}
          submitted={submitted}
        />
      </div>

      <div className="my-6 flex flex-col justify-center">
        <GrammarInputComponent
          title={"Example Exercise:"}
          value={exampleExercise}
          setValue={setExampleExercise}
          placeholder={"Example Exercise"}
          submitted={submitted}
        />
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-2 gap-4 mb-4">
        <div>
          <label className="mb-2 font-semibold text-2xl">Number of exercises:</label>
          <input
            type="number"
            className="w-full border-b-2 focus:border-gray-400 rounded-lg p-2 font-medium text-lg outline-none shadow"
            onChange={(event) => setNumberOfExercises(event.target.value)}
            value={numberOfExercises}
            max={25}
            min={1}
          />
          <p className="text-gray-600 mt-1.5 font-medium">Max: 25</p>
        </div>
        <div>
          <label className="mb-2 font-semibold text-2xl">Difficulty:</label>
          <select
            className="w-full border-b-2 focus:border-gray-400 rounded-lg p-2.5 font-medium text-lg outline-none shadow"
            onChange={(event) => setDifficulty(event.target.value)}
            value={difficulty}
          >
            <option value="easy">Easy</option>
            <option value="medium">Medium</option>
            <option value="hard">Hard</option>
          </select>
        </div>
      </div>


      <div className="flex justify-center py-12">
        <button
          className={`${!submitIsValid() === true ? "text-gray-500" : "text-white"} bg-blue-600 border-2 border-blue-600 py-2.5 px-10 rounded-full font-medium text-lg transition-colors duration-300 ease-in-out
                    hover:bg-blue-700 hover:border-blue-600
                    active:bg-blue-800 active:border-blue-700 active:shadow-inner
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                    shadow-color-lg shadow-blue-400
                    disabled:bg-gray-200 disabled:border-gray-300 disabled:shadow-gray-300 disabled:cursor-not-allowed disabled:text-gray-500`}
          onClick={handleSubmit}
          disabled={!submitIsValid()}
        >
          Generate grammar exercises
        </button>
      </div>
    </div>
  );
};

export default GrammarInput;
