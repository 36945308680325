import React, { useState, useEffect, useRef } from 'react';
import '../../styles/FlashcardComponent.css'; // Ensure this points to your updated 
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
import { faStar, faUndo } from "@fortawesome/free-solid-svg-icons";
import api from '../../api';

const FlashcardComponent = ({
  cards,
  cardIndex,
  setCardIndex,
  setNumberOfCorrect,
  setNumberOfIncorrect,
  setShowRecap,
  flashcardSetUuid,
  questionType,
}) => {

  const [localCardIndex, setLocalCardIndex] = useState(cardIndex || 0);
  const [isFlipped, setIsFlipped] = useState(false);
  const [animationDirection, setAnimationDirection] = useState('');
  const [windowSize, setWindowSize] = useState(window.innerWidth);
  const [isRequestPending, setIsRequestPending] = useState(false);
  const [isAnimating, setIsAnimating] = useState(false);

  const [goBackButtonDisabled, setGoBackButtonDisabled] = useState(true);
  const [numberOfGoBacks, setNumberOfGoBacks] = useState(0);

  const [currentCardData, setCurrentCardData] = useState(null);

  const [isGoBack, setIsGoBack] = useState(false);
  const [previousCardData, setPreviousCardData] = useState(null);


  useEffect(() => {
    function handleResize() {
      setWindowSize(window.innerWidth);
    }
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const iconSize = windowSize < 768 ? 'xs' : 'sm';

  const toggleStar = () => {
    const newStarredStatus = !currentCardData.starred;
    const updatedCardData = { ...currentCardData, starred: newStarredStatus };
    setCurrentCardData(updatedCardData);
  
    api.put('/api/flashcards/update_starred/', {
      uuid: currentCardData.uuid,
      starred: newStarredStatus,
    }).catch((error) => {
      console.error('Error updating starred status:', error);

      setCurrentCardData(currentCardData);
    });
  
  };
  

  useEffect(() => {
    setCurrentCardData(cards[localCardIndex]);
  }, [localCardIndex, cards]);
  
  




  const handleNavigationCorrection = (direction) => {
    if (isAnimating) return;
  


    const nextIndex = (localCardIndex + 1) % cards.length;
    const nextCard = cards[nextIndex];
  
    setPreviousCardData(currentCardData); 
    setCurrentCardData(nextCard);
    
    setIsAnimating(true);
    setIsFlipped(false);
    setIsRequestPending(true);
    setAnimationDirection(direction);

    console.log("this is the current card data: ", currentCardData);
    console.log("this is previous card data: ", previousCardData);
  
    //FIXME: change this: uuid: previousCardData ? previousCardData.uuid : currentCardData.uuid

    const data = {
      uuid: currentCardData.uuid,
      correct_incorrect: direction,
      flashcard_set_uuid: flashcardSetUuid,
      question_type: questionType,
    };
  
    api.put('/api/flashcards/flashcard/correct_incorrect/', data)
      .then(() => {

        if (direction === 'true') {
          setNumberOfCorrect((prev) => prev + 1);
        } else {
          setNumberOfIncorrect((prev) => prev + 1);
        }

        setTimeout(() => {
          if (localCardIndex === cards.length - 1) {
            return setShowRecap(true);
          }
        }, 650);
  
        setTimeout(() => {
          setLocalCardIndex(nextIndex);
          setAnimationDirection('');
          setIsRequestPending(false);
          setCardIndex(nextIndex);
          setIsAnimating(false);
          setGoBackButtonDisabled(false);
          setNumberOfGoBacks(prev => (prev < 3 ? prev + 1 : prev));
        }, 750);
      })
      .catch((error) => {
        console.error('Error updating flashcard status:', error);
        setIsAnimating(false);
        setIsRequestPending(false);
        setAnimationDirection('');
      });
  };


  const handleGoBack = () => {
    if (isAnimating) return;

    const prevIndex = localCardIndex - 1;
    if (prevIndex < 0) return; // No previous card to go back to
    setIsGoBack(true);
    setGoBackButtonDisabled(true);
    setIsAnimating(true);
    const data = {
      uuid: cards[prevIndex].uuid,
      flashcard_set_uuid: flashcardSetUuid,
    };

    api.put("/api/flashcards/flashcard_mode_go_back/", data)
      .then((response) => {
        const wasCorrect = response.data.correct_incorrect === true;
        if (wasCorrect) {
          setNumberOfCorrect((prev) => prev - 1);
          setAnimationDirection("goBackTrue");
        } else {
          setNumberOfIncorrect((prev) => prev - 1);
          setAnimationDirection("goBackFalse");
        }
        setNumberOfGoBacks((prev) => prev - 1);

        setPreviousCardData(cards[prevIndex]); 

        setIsFlipped(false);

        setTimeout(() => {
          setCurrentCardData(cards[prevIndex]); // Now update to previous card
          setLocalCardIndex(prevIndex);
          setCardIndex(prevIndex);
          setIsAnimating(false);
          setAnimationDirection("");
          setGoBackButtonDisabled(false);
          setIsGoBack(false);
        }, 750);
      })
      .catch((error) => {
        console.error("Error updating flashcard status:", error);
        setIsAnimating(false);
        setAnimationDirection("");
        setGoBackButtonDisabled(false);
      });
  };

  const toggleFlip = () => {
    setIsFlipped(!isFlipped);
  };



  
  //          {isAnimating ? (isGoBack ? currentCardData?.front : previousCardData?.front) : currentCardData?.front}
  return (
    <div
      className={`w-[325px] h-[440px] sm:w-[585px] sm:h-[375px] md:w-[732px] md:h-[500px] lg:w-[975px] lg:h-[550px] relative`}
    >
      <div className="card-stack relative w-full h-full perspective-container">
        <div 
          className={`card current-card absolute w-full h-full cursor-pointer rounded-2xl border-2 border-gray-300 ${isFlipped ? 'flipped shadow-flipped' : 'shadow-normal'} ${animationDirection}`}
          onAnimationEnd={() => {setIsAnimating(false); setAnimationDirection('');}}
          onClick={!isAnimating ? toggleFlip : undefined}
        >
          <div className="card-face card-front absolute w-full h-full flex items-center justify-center text-2xl md:text-3xl text-black select-none">
            {isAnimating ? (isGoBack ? currentCardData?.front : previousCardData?.front) : currentCardData?.front}

            {!isFlipped && (
              <button
                onClick={(e) => {
                  e.stopPropagation();
                  toggleStar();
                }}
                className="absolute top-0 left-0 m-4 outline-none"
              >
              {(isAnimating ? (isGoBack ? currentCardData?.starred : previousCardData?.starred) : currentCardData?.starred) ? (
                  <FontAwesomeIcon icon={faStar} className="text-yellow-400" size={iconSize} />
                ) : (
                  <FontAwesomeIcon icon={farStar} className="text-gray-600" size={iconSize} />
                )}
              </button>
            )}
          </div>
          <div className="card-face card-back absolute w-full h-full flex items-center justify-center text-3xl text-black select-none">
            {isAnimating ? (isGoBack ? currentCardData?.back : previousCardData?.back) : currentCardData?.back}
          </div>
        </div>
        {isAnimating && (localCardIndex !== (cards.length-1) || isGoBack) && (
          <div
            className={`card next-card absolute w-full h-full rounded-2xl border-2 border-gray-300 ${animationDirection} shadow-normal`}
          >
            <div className="card-face card-front absolute w-full h-full flex items-center justify-center text-2xl md:text-3xl text-black select-none">
            {isGoBack ? previousCardData?.front : currentCardData?.front}

              {!isFlipped && (
                <div
                  className="absolute top-0 left-0 m-4 outline-none"
                >
                  {(isGoBack ? previousCardData?.starred : currentCardData?.starred) ? (
                    <FontAwesomeIcon icon={faStar} className="text-yellow-400" size={iconSize} />
                  ) : (
                    <FontAwesomeIcon icon={farStar} className="text-gray-600" size={iconSize} />
                  )}
                </div>
              )}
            </div>
            <div className="card-face card-back absolute w-full h-full flex items-center justify-center text-3xl text-black select-none">
            {isGoBack ? previousCardData?.back : currentCardData?.back}

            </div>
          </div>
        )}    
      </div>

      <div className="flex justify-between items-center mt-5 w-full">
        <button 
          disabled={goBackButtonDisabled || numberOfGoBacks <= 0} 
          className={`${(goBackButtonDisabled || numberOfGoBacks <= 0) && "opacity-50"}`}
          onClick={handleGoBack}
        >
          <FontAwesomeIcon icon={faUndo} className="text-2xl" color={'#52525b'} />
        </button>

        <div className="flex justify-center items-center gap-20">
          <div className="bg-white flex justify-center items-center rounded-full">
            <button
              className="hover:bg-red-200 p-2 rounded-full border-2 border-red-500 hover:border-red-600 focus:outline-none transition duration-150 ease-in-out"
              onClick={() => handleNavigationCorrection('false')}
              disabled={isRequestPending}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#EF4444"
                strokeWidth="2.5"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M6 6l12 12M6 18L18 6" />
              </svg>
            </button>
          </div>

          <div className="bg-white flex justify-center items-center rounded-full">
            <button
              className="hover:bg-green-200 p-2 rounded-full border-2 border-green-500 hover:border-green-600 focus:outline-none transition duration-150 ease-in-out"
              onClick={() => handleNavigationCorrection('true')}
              disabled={isRequestPending}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="h-6 w-6"
                fill="none"
                viewBox="0 0 24 24"
                stroke="#10B981"
                strokeWidth="2.5"
              >
                <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
              </svg>
            </button>
          </div>
        </div>


        <FontAwesomeIcon icon={faUndo} className="text-2xl" color={'#F6F7FB'} />
      </div>
    </div>
  );
};

export default FlashcardComponent;













// This is the original code before upating everything:


// import React, { useState, useEffect, useRef } from 'react';
// import '../../styles/FlashcardComponent.css'; // Ensure this points to your updated 
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faStar as farStar } from '@fortawesome/free-regular-svg-icons';
// import { faStar, faUndo } from "@fortawesome/free-solid-svg-icons";
// import api from '../../api';

// const FlashcardComponent = ({
//   cards,
//   initialCardIndex,
//   setCardIndex,
//   setNumberOfCorrect,
//   setNumberOfIncorrect,
//   setShowRecap,
//   flashcardSetUuid,
//   questionType,
// }) => {

//   const [localCardIndex, setLocalCardIndex] = useState(initialCardIndex || 0);
//   const [isFlipped, setIsFlipped] = useState(false);
//   const [animationDirection, setAnimationDirection] = useState('');
//   const [isStarClicked, setIsStarClicked] = useState(false);
//   const [windowSize, setWindowSize] = useState(window.innerWidth);
//   const [isRequestPending, setIsRequestPending] = useState(false);
//   const [isAnimating, setIsAnimating] = useState(false);

//   const [goBackButtonDisabled, setGoBackButtonDisabled] = useState(true);
//   const [numberOfGoBacks, setNumberOfGoBacks] = useState(0);

//   const [currentCardData, setCurrentCardData] = useState(cards[localCardIndex]);
//   const [nextCardData, setNextCardData] = useState(cards[(localCardIndex + 1) % cards.length]);
//   const [previousCardData, setPreviousCardData] = useState(null);


//   useEffect(() => {
//     function handleResize() {
//       setWindowSize(window.innerWidth);
//     }
//     window.addEventListener('resize', handleResize);
//     return () => window.removeEventListener('resize', handleResize);
//   }, []);

//   const iconSize = windowSize < 768 ? 'xs' : 'sm';

//   const toggleStar = () => {
//     setIsStarClicked(!isStarClicked);
//     const data = {
//       uuid: currentCardData.uuid,
//       starred: !isStarClicked,
//     };
//     api.put('/api/flashcards/update_starred/', data);
//   };


//   useEffect(() => {
//     if (!isAnimating) {
//       setCurrentCardData(cards[localCardIndex]);
//       setNextCardData(cards[(localCardIndex + 1) % cards.length]);
//       setIsStarClicked(cards[localCardIndex]?.starred || false);
//     }
//   }, [localCardIndex, cards, isAnimating]);
  




//   //FIXME: this was the code before where ther was a small lag in updating the current card data

//   // useEffect(() => {
//   //   if (!isAnimating) {
//   //     setCurrentCardData(cards[localCardIndex]);
//   //     setNextCardData(cards[(localCardIndex + 1) % cards.length]);
//   //     setIsStarClicked(cards[localCardIndex]?.starred || false);
//   //   }
//   // }, [localCardIndex, cards, isAnimating]);





//   const handleNavigationCorrection = (direction) => {
//     if (isAnimating) return;

//     setPreviousCardData(currentCardData);

//     setIsAnimating(true);
//     setIsFlipped(false);
//     setIsRequestPending(true);
//     setAnimationDirection(direction);

//     const data = {
//       uuid: currentCardData.uuid,
//       correct_incorrect: direction,
//       flashcard_set_uuid: flashcardSetUuid,
//       question_type: questionType,
//     };

//     api.put('/api/flashcards/flashcard/correct_incorrect/', data)
//       .then(() => {
//         if (direction === 'true') {
//           setNumberOfCorrect((prev) => prev + 1);
//         } else {
//           setNumberOfIncorrect((prev) => prev + 1);
//         }

//         const nextIndex = (localCardIndex + 1) % cards.length;
//         setTimeout(() => {
//           setIsAnimating(false);
//           setAnimationDirection('');
//           setIsRequestPending(false);
//           if (localCardIndex === cards.length - 1) {
//             setShowRecap(true);
//           } else {
//             // Update local card index without affecting the parent component yet
//             setLocalCardIndex(nextIndex);
//           }


//           // Update the parent component after animation completes
//           setCardIndex(nextIndex);
//         }, 750); // Match this with your animation duration
//       })
//       .catch((error) => {
//         console.error('Error updating flashcard status:', error);
//         setIsAnimating(false);
//         setIsRequestPending(false);
//         setAnimationDirection('');
//       });
//   };

//   const handleGoBack = () => {
//     setGoBackButtonDisabled(true);


//     //TODO: disbale the button
//     //TODO: add that it counts the number of go backs: Max: 5

//     //TODO: add the animation logic
//     //TODO: add the logic to update the card index
//   };



//   const toggleFlip = () => {
//     setIsFlipped(!isFlipped);
//   };

//   return (
//     <div
//       className={`w-[325px] h-[440px] sm:w-[585px] sm:h-[375px] md:w-[732px] md:h-[500px] lg:w-[975px] lg:h-[550px] relative`}
//     >
//       <div className="card-stack relative w-full h-full perspective-container">
//         <div
//           className={`card current-card absolute w-full h-full cursor-pointer rounded-2xl border-2 border-gray-300 ${
//             isFlipped ? 'flipped shadow-flipped' : 'shadow-normal'
//           } ${animationDirection}`}
//           onAnimationEnd={() => setAnimationDirection('')}
//           onClick={!isAnimating ? toggleFlip : undefined}
//         >
//           <div className="card-face card-front absolute w-full h-full flex items-center justify-center text-2xl md:text-3xl text-black select-none">
//             {currentCardData?.front}
//             {!isFlipped && (
//               <button
//                 onClick={(e) => {
//                   e.stopPropagation();
//                   toggleStar();
//                 }}
//                 className="absolute top-0 left-0 m-4 outline-none"
//               >
//                 {isStarClicked ? (
//                   <FontAwesomeIcon icon={faStar} className="text-yellow-400" size={iconSize} />
//                 ) : (
//                   <FontAwesomeIcon icon={farStar} className="text-gray-600" size={iconSize} />
//                 )}
//               </button>
//             )}
//           </div>
//           <div className="card-face card-back absolute w-full h-full flex items-center justify-center text-3xl text-black select-none">
//             {currentCardData?.back}
//           </div>
//         </div>

//         {/* Next Card */}
//         {isAnimating && (
//           <div
//             className={`card next-card absolute w-full h-full rounded-2xl border-2 border-gray-300 ${animationDirection}`}
//           >
//             <div className="card-face card-front absolute w-full h-full flex items-center justify-center text-2xl md:text-3xl text-black select-none">
//               {nextCardData?.front}
//             </div>
//             <div className="card-face card-back absolute w-full h-full flex items-center justify-center text-3xl text-black select-none">
//               {nextCardData?.back}
//             </div>
//           </div>
//         )}
//       </div>

//       {/* Controls */}
//       <div className="flex justify-between items-center mt-5 w-full">
//         <button disabled={goBackButtonDisabled}>
//           <FontAwesomeIcon icon={faUndo} className="text-2xl" color={'#52525b'} />
//         </button>

//         <div className="flex justify-center items-center gap-20">
//           <div className="bg-white flex justify-center items-center rounded-full">
//             <button
//               className="hover:bg-red-200 p-2 rounded-full border-2 border-red-500 hover:border-red-600 focus:outline-none transition duration-150 ease-in-out"
//               onClick={() => handleNavigationCorrection('false')}
//               disabled={isRequestPending}
//             >
//               {/* X Icon */}
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="h-6 w-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="#EF4444"
//                 strokeWidth="2.5"
//               >
//                 <path strokeLinecap="round" strokeLinejoin="round" d="M6 6l12 12M6 18L18 6" />
//               </svg>
//             </button>
//           </div>

//           <div className="bg-white flex justify-center items-center rounded-full">
//             <button
//               className="hover:bg-green-200 p-2 rounded-full border-2 border-green-500 hover:border-green-600 focus:outline-none transition duration-150 ease-in-out"
//               onClick={() => handleNavigationCorrection('true')}
//               disabled={isRequestPending}
//             >
//               <svg
//                 xmlns="http://www.w3.org/2000/svg"
//                 className="h-6 w-6"
//                 fill="none"
//                 viewBox="0 0 24 24"
//                 stroke="#10B981"
//                 strokeWidth="2"
//               >
//                 <path strokeLinecap="round" strokeLinejoin="round" d="M5 13l4 4L19 7" />
//               </svg>
//             </button>
//           </div>
//         </div>

//         {/* Placeholder for spacing */}
//         <FontAwesomeIcon icon={faUndo} className="text-2xl" color={'#F6F7FB'} />
//       </div>
//     </div>
//   );
// };

// export default FlashcardComponent;

