import { useRef, useState } from 'react';
import Slider from 'react-slick';
import '../../styles/FlashcardCarousel.css'; 

import 'slick-carousel/slick/slick.css'; 
import 'slick-carousel/slick/slick-theme.css';

const FlashcardCarousel = ({ flashcards }) => {
  const [flippedCardIndex, setFlippedCardIndex] = useState(null);
  const [currentSlide, setCurrentSlide] = useState(0);  
  const sliderRef = useRef(null);

  const toggleFlip = (index) => {
    if (index === currentSlide) {

      setFlippedCardIndex(flippedCardIndex === index ? null : index); 
    }
  };

  const settings = {
    dots: false,
    infinite: true,
    speed: 750,
    slidesToShow: 1,        
    slidesToScroll: 1,
    centerMode: true,      
    centerPadding: '60px',
    adaptiveHeight: true,
    arrows: false,
    afterChange: (current) => {
      setCurrentSlide(current);
      setFlippedCardIndex(null); // Reset the flipped state when slide changes
    },       
  };

  return (
    <div className="mb-12">
      <Slider ref={sliderRef} {...settings}>
        {flashcards.map((card, index) => (
          <div
            key={index}
            className="perspective-container max-w-[800px] mx-auto h-[450px] py-5 px-4 "
          >
            <div
              onClick={() => toggleFlip(index)}
              className={`card relative ${
                flippedCardIndex === index
                  ? 'flipped shadow-flipped'
                  : 'shadow-normal'
              } bg-white rounded-2xl border-2 border-gray-300 h-full`}
            >
              <div className="absolute w-full h-full flex items-center justify-center text-2xl md:text-3xl text-black select-none card-face card-front">
                {card.word}
              </div>
              <div className="absolute w-full h-full flex items-center justify-center text-2xl md:text-3xl text-black select-none card-face card-back">
                {card.definition}
              </div>
            </div>
          </div>
        ))}
      </Slider>
      <div className="flex justify-center gap-28">
        <div className='bg-white rounded-full'>
          <button className=' hover:bg-gray-200 p-2 rounded-full border-2 border-gray-300 hover:border-gray-400 focus:outline-none transition duration-150 ease-in-out' onClick={() => sliderRef.current.slickPrev()}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="gray">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 19l-7-7 7-7" />
            </svg>
          </button>
        </div>
        <div className='bg-white rounded-full'>
          <button className='hover:bg-gray-200 p-2 rounded-full border-2 border-gray-300 hover:border-gray-400 focus:outline-none transition duration-150 ease-in-out' onClick={() => sliderRef.current.slickNext()}>
            <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="gray">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M9 5l7 7-7 7" />
            </svg>
          </button>
        </div>
      </div>
    </div>
  );
};

export default FlashcardCarousel;
