import api from "./../api";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { notify } from "../providers/NotificationProvider";
import ConfirmDeletionModal from "../components/ConfirmDeletionModal";


const AllFlashcards = () => {
  const [loading, setLoading] = useState(true);
  const [flashcardDrafts, setFlashcardDrafts] = useState([]);
  const [flashcardData, setFlashcardData] = useState([]);
  const [reloadTrigger, setReloadTrigger] = useState(false);
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);
  const [deleteDraftUuid, setDeleteDraftUuid] = useState(null);


  const navigate = useNavigate();

  useEffect(() => {
    const urls = ["/api/flashcards/flashcard_set_title_uuid/", "/api/flashcards/get_all_flashcard_drafts/"];

    Promise.all(urls.map((url) => api.get(url)))
    .then((responses) => {
      setFlashcardData(responses[0].data);
      setFlashcardDrafts(responses[1].data);
      console.log("flashcardDrafts: ", responses[1].data);
      setLoading(false);
    })
    .catch((error) => {
      
      console.error(error);
    });
  }, [reloadTrigger]);



  const handleDeleteDraft = () => {
    api.delete(`/api/flashcards/delete_flashcard_draft/${deleteDraftUuid}/`)
    .then((response) => {
      console.log(response.data);
      notify({message: "Draft deleted successfully!", type: "success"});
      setReloadTrigger(!reloadTrigger);
    })
    .catch((error) => {
      console.error(error);
    });
  };




  return (
    <div style={{ padding: "0 13vw", backgroundColor: "#F6F7FB" }} className="min-h-screen">
      <ConfirmDeletionModal showModal={showConfirmDeletionModal} setShowModal={setShowConfirmDeletionModal} handleDelete={handleDeleteDraft} header={"Delete Draft?"} text={"Are you sure you want to delete the draft? This action cannot be undone."} buttonLabel={"Delete"} />
      {!loading && (
        <div className="py-10">
          <div className="w-full flex justify-between items-center mb-10">
            <h1 className="text-3xl font-semibold mb-0 flex items-center">
              Flashcard libary
            </h1>
            <button
              className="text-blue-600 hover:text-blue-700 flex items-center px-3 py-2 rounded-lg transition duration-300 ease-in-out hover:bg-blue-200"
              onClick={() => navigate("/flashcard/input/")}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="text-2xl mr-2"
                color="rgb(37 99 235)"
              />
              <span className="text-xl text-blue-600 font-medium">Create</span>
            </button>
          </div>

          <div className="grid gird-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
            {flashcardData.length > 0 && flashcardDrafts.map((draft, index) => (
              <div 
                key={draft.uuid} 
                className="bg-white shadow transition duration-300 ease-in-out transform hover:scale-105 border-gray-300 hover:border-blue-600 rounded-xl" 
                style={{ height: 188}}
              >
                <div className="p-4 flex flex-col items-start" onClick={() => navigate(`/flashcard/input/${draft.uuid}/`)}>
                  <div className="flex justify-start space-x-2">
                    <p className="font-semibold text-lg mb-3">
                      {draft.title || "Untitled"}
                    </p>
                    <span className="text-blue-600 font-semibold text-lg">(Draft)</span>
                  </div>
                  <p className="rounded-full bg-gray-200 px-2 py-1 text-sm font-medium">
                    Terms: {draft.words.length}
                  </p>
                  <div className="w-full flex justify-end">
                    <button 
                      className="flex justify-end items-center font-medium text-red-500 gap-2.5 py-2 px-2.5 rounded-lg hover:bg-red-100" 
                      style={{ marginTop: 28 }}
                      onClick={(e) => {
                        e.stopPropagation();
                        setDeleteDraftUuid(draft.uuid);
                        setShowConfirmDeletionModal(true);
                      }}
                    >
                      <FontAwesomeIcon icon={faTrash} />
                      <p>Delete Draft</p>
                    </button>
                  </div>
                </div>
              </div>
            ))}


            {flashcardData.map((set, index) => (
              <div key={set.uuid} className="bg-white rounded-xl shadow transition duration-300 ease-in-out transform hover:scale-105">
                <a href={"/flashcard/preview/" + set.uuid + "/"} className="border-gray-300 rounded-xl p-4 hover:border-blue-600 flex flex-col items-start ">
                  <div className="font-semibold text-lg mb-3">{set.title}</div>
                  <div className="rounded-full bg-gray-200 px-2 py-1 text-sm font-medium">
                    Terms: {set.number_of_flashcards}
                  </div>

                  <label className="w-full font-semibold mb-2 mt-4 text-sm">{set.performance}% learned</label>
                  <div className="h-4 bg-gray-200 rounded-full w-full">
                    <div
                      className={`bg-green-400 h-4 rounded-full`}
                      style={{ width: `${set.performance}%` }}
                    />
                  </div>
                </a>
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default AllFlashcards;
