import { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";

const NoSetField = ({ buttonText, header, description, type }) => {
  const containerRef = useRef(null);
  const [containerWidth, setContainerWidth] = useState(300);
  const navigate = useNavigate();

  const updateDimensions = () => {
    const width = window.innerWidth;
    const minWidth = width >= 768 ? 450 : 300;

    if (containerRef.current) {
      const container = containerRef.current;
      const width = container.clientWidth > minWidth ? container.clientWidth : minWidth; 
      setContainerWidth(width);
    }
  };

  useEffect(() => {

    const timer = setTimeout(updateDimensions, 10);

    window.addEventListener('resize', updateDimensions);

    return () => {
      window.removeEventListener('resize', updateDimensions); 
      clearTimeout(timer); 
    };
  }, []);

  const handleButtonClicked = () => {
    switch (type) {
      case "exam":
        navigate("/exam/input/");
        break;
      case "flashcard":
        navigate("/flashcard/input/");
        break;
      case "grammar":
        navigate("/grammar/input/");
        break;
    }
  };


  return (
    <div ref={containerRef} className="bg-white h-[188px] rounded-lg shadow my-4" style={{ width: `${containerWidth}px`, marginLeft: "15px" }}>
      <div className="p-4 w-full flex flex-col justify-center items-center">
        <p className="text-lg font-medium">{header}</p>
        <p className="text-center mt-2 text-gray-400 text">{description}</p>
        <button className="bg-blue-600 py-2 rounded-md w-full mt-3 text-white font-semibold" onClick={handleButtonClicked}>{buttonText}</button>
      </div>
    </div>
  );
};

export default NoSetField;
