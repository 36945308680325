import { useState, useEffect } from "react"
const TitleInput = ({ value, setValue, submitted }) => {
  const [error, setError] = useState(false);
  useEffect(() => {
    if (submitted && value.trim() === "") {
      setError(true);
    }
    else {
      setError(false);
    }
  }, [submitted, value]);

  return (
    <div className="pt-8">
      <div className="flex flex-col justify-start w-full bg-white rounded-lg shadow mb-10">
        <input
          id="title" 
          autoFocus
          type="text" 
          className= {`rounded-md text-xl font-medium border-b-2 ${error ? "shadow-color-lg shadow-red-400 border-red-500" : " focus:border-gray-400"} py-2.5 px-3 focus:outline-none outline-none `}
          placeholder="Enter a title"
          onChange={(event) => setValue(event.target.value)}
          value={value}
        />
        {error && <p className="text-red-500 text-sm ">Title is required</p>}
      </div>
    </div>
  )

}
export default TitleInput;