import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import 'reactjs-popup/dist/index.css';
import TitleInput from "../components/InputComponents/TitleInput";
import useAxiosGet from "../hooks/useAxiosGet";
import api from "../api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFileLines} from "@fortawesome/free-solid-svg-icons";
import TextIcon from "../components/SVG/TextIcon";
import { motion } from "framer-motion";
import ExamUploadDocument from "../components/Exam/ExamUploadDocument";
import ExamUploadText from "../components/Exam/ExamUploadText";
import Lottie from 'react-lottie';
import animationData from "../Assets/Lottie/generate-from-file.json";
import LimitModal from "../components/Modals/LimitModal";




const tabs = [
  {
    id: "text",
    label: "Upload Text",
  },
  {
    id: "file",
    label: "Upload File",
  },
]


const ExamInput = () => {
  const [title, setTitle] = useState("");
  const [difficulty, setDifficulty] = useState("medium");
  const [submitted, setSubmitted] = useState(false);
  const [maxCharacters, setMaxCharacters] = useState(10_000);
  const [maxQuestions, setMaxQuestions] = useState(25);
  const [file, setFile] = useState(null);
  const [fileText, setFileText] = useState("");
  const [text, setText] = useState("");
  const [generating, setGenerating] = useState(false);
  const [numberOfQuestionsList, setNumberOfQuestionsList] = useState([0, 0, 0, 0, 0]);
  const [isValid, setIsValid] = useState(false);
  const [limitReason, setLimitReason] = useState(""); 
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [activeTab, setActiveTab] = useState(tabs[0].id);

  const [fileUploadErrors, setFileUploadErrors] = useState("");


  const navigate = useNavigate();






  const { data, error } = useAxiosGet("/api/subscription/");
  useEffect(() => {
    if (data) {
      console.log("subscription data", data);
      // if ((data.tokens-25) >= 0) {
      //   setMaxQuestions(25);
      // } else {
      //   setMaxQuestions(data.tokens);
      // }

      const plan = data.plan;
      if (plan === "basic") {
        setMaxCharacters(10_000);
      }
      if (plan === "pro") {
        setMaxCharacters(25_000);
      }
      if (plan === "premium") {
        setMaxCharacters(50_000);
      }

    }
    if (error) {
      console.error(error);
    }
  }, [data, error]);


  const uploadDocx = () => {
    const formData = new FormData();
    formData.append("file", file);
    formData.append("data", JSON.stringify({
      title: title,
      number_of_multiple_choice_questions: numberOfQuestionsList[0],
      number_of_true_or_false_questions: numberOfQuestionsList[1],
      number_of_fill_in_the_blank_questions: numberOfQuestionsList[2],
      number_of_short_answer_questions: numberOfQuestionsList[3],
      number_of_essay_questions: numberOfQuestionsList[4],
      difficulty: difficulty,
    }));
    api.post("/api/upload_file/exam/", formData, { headers: { 'Content-Type': 'multipart/form-data' } })
      .then((response) => {
        console.log("this is the response from the backend: ", response.data);
        
        if (!response.data.enough_tokens && response.data.enough_tokens !== undefined) {
          setLimitReason("tokens");
          setShowLimitModal(true);
          return;
        }
        setGenerating(false);
        //navigate("/exam/questions/" + response.data.uuid + "/");
        console.log("this is the response the backend: ", response.data);

      })
      .catch((error) => {
        if (error.response && error.response.data) {
          console.log(error.response.data);
          const errorMessage = error.response.data.error || 'An error occurred while uploading the file.';
          setFileUploadErrors(errorMessage);
          setFile(null);
          setFileText("");
        } else {
          setFileUploadErrors('Error uploading file. Please try again.');
        }
        setGenerating(false);
        console.error(error);
      })
  };




  const handleSubmit = () => {
    setSubmitted(true);
    setGenerating(true);

    if (file !== null) {
      console.log("Uploading DOCX file");
      return uploadDocx();
    } else {
      // TODO: add here that it should maybe also add the file text if there is one
      // TODO: change that it uses instead of the summary the text?
      console.log("this is the fileText: ", fileText);
      console.log("this is the text: ", text);
      console.log("this is getting send to the backend: ", text || fileText);
      const data = {
        summary: text || fileText,
        title: title,
        number_of_multiple_choice_questions: numberOfQuestionsList[0],
        number_of_true_or_false_questions: numberOfQuestionsList[1],
        number_of_fill_in_the_blank_questions: numberOfQuestionsList[2],
        number_of_short_answer_questions: numberOfQuestionsList[3],
        number_of_essay_questions: numberOfQuestionsList[4],
        difficulty: difficulty,
      };
      console.log(data)


        api.post("/api/summary_input/", data)
        .then((response) => {
          console.log("response from the backend")
          console.log(response.data);

          const enough_tokens = response.data.enough_tokens;
          console.log("this is the enough tokens")
          console.log(enough_tokens);


          if (!response.data.enough_tokens && response.data.enough_tokens !== undefined) {
            setGenerating(false);
            setLimitReason("tokens");
            setShowLimitModal(true);
            return;
          } else {
            setGenerating(false);
            navigate("/exam/questions/" + response.data.uuid + "/");
          }
        })
        .catch((error) => {
          console.error(error);
        })
        .finally(() => {
          setGenerating(false);
        });
    }

  };



  useEffect(() => {
    const isContentValid = Boolean(text) || Boolean(fileText) || file != null;
    console.log("isContentValid: ", isContentValid);
    setIsValid(title !== "" && isContentValid && numberOfQuestionsList.reduce((sum, num) => sum + num, 0) !== 0);
  }, [title, text, fileText, file, numberOfQuestionsList]);




  const updateNumberOfQuestionsField = (index, value) => {
    const newValues = [...numberOfQuestionsList];
    newValues[index] = parseInt(value);
    setNumberOfQuestionsList(newValues);
  };

  const remainingForIndex = (index) => {
    const currentTotal = numberOfQuestionsList.reduce((acc, cur, idx) => acc + (idx !== index ? cur : 0), 0);
    return maxQuestions - currentTotal;
  };

  const createPickerItems = (max) => {
    let items = [];
    for (let i = 0; i <= max; i++) {
      items.push({ label: i.toString(), value: i });
    }
    return items;
  };
  



  const numberOfQuestionsTitlesList = ["Multiple Choice", "True or False", "Fill in the Blank", "Short Answer", "Essay"];




  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };


  if (generating) {
    return (
      <div className='h-[600px] flex flex-col items-center justify-center min-h-screen max-h-screen' style={{ background: "#F6F7FB" }}>
        <Lottie 
          options={defaultOptions}
          height={550}
          width={550}
        />
        <h1 className='text-2xl font-semibold text-purple-500'>Generating Exam...</h1>
      </div>
    );
  }
  return (
    <div style={{ padding: "0 13vw", backgroundColor: "#F6F7FB" }}>

      <LimitModal limitReason={limitReason} showModal={showLimitModal} setShowModal={setShowLimitModal} />



      <TitleInput value={title} setValue={setTitle} submitted={submitted} />

      <div className="h-5" />
      <div className="flex flex-col xl:flex-row justify-between items-start xl:items-center mb-6">
        <div>
          {activeTab === "text" ? (
            <>
              <h1 className="text-2xl font-semibold">Upload Text</h1>
              <h3 className="mt-2 text-lg">
                Copy and paste your content. Your character limit is{" "}
                {maxCharacters.toLocaleString()} characters.
              </h3>
            </>
          ) : (
            <>
              <h1 className="text-2xl font-semibold">Upload File</h1>
              <h3 className="mt-2 text-lg">
                Please ensure your file does not exceed{" "}
                {maxCharacters.toLocaleString()} characters and is no larger
                than 5 MB.
              </h3>
            </>
          )}
        </div>
        <div className=" xl:mt-0 xl:ml-6">
          <div className="bg-white rounded-full shadow-md shadow-neutral-300 border-2 border-gray-300 inline-block">
            <div className="inline-flex space-x-1 p-2">
              {tabs.map((tab) => {
                const isActive = activeTab === tab.id;
                const changeActiveTab = () => {
                  setFile(null);
                  setText("");  
                  setFileText("");
                  setActiveTab(tab.id);
                };
                return (
                  <button
                    key={tab.id}
                    tabIndex={-1}
                    onClick={changeActiveTab}
                    className={`
                      flex items-center relative rounded-full px-4 py-2.5 font-medium
                      outline-sky-400 transition-colors duration-300 focus-visible:outline-2
                      ${isActive ? "text-white" : "text-blue-600 hover:text-blue-700"}
                    `}
                    style={{
                      WebkitTapHighlightColor: "transparent",
                    }}
                  >
                    {isActive && (
                      <motion.span
                        layoutId="bubble"
                        className="absolute inset-0 z-10 bg-blue-600"
                        style={{ borderRadius: "9999px" }}
                        transition={{
                          type: "spring",
                          bounce: 0.2,
                          duration: 0.6,
                        }}
                        animate={{ opacity: 0.9 }}
                        initial={{ opacity: 0.9 }}
                      />
                    )}
                    <span className="z-20 relative flex items-center">
                      {tab.id === "text" ? (
                        <TextIcon
                          className={`
                            w-6 h-6 mr-2
                            ${isActive ? "text-white" : "text-blue-600"}
                            transition-colors duration-300
                          `}
                        />
                      ) : (
                        <FontAwesomeIcon
                          icon={faFileLines}
                          className={`
                            w-6 h-6 mr-2
                            ${isActive ? "text-white" : "text-blue-600"}
                            transition-colors duration-300
                          `}
                        />
                      )}
                      {tab.label}
                    </span>
                  </button>
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div>
        {activeTab === "text" ? (

          <ExamUploadText
            userCharacterLimit={maxCharacters}
            text={text}
            setText={setText}
          />
        ) : (
          <ExamUploadDocument
            setFile={setFile}
            userCharacterLimit={maxCharacters}
            setFileText={setFileText}
            fileUploadErrors={fileUploadErrors}
            setFileUploadErrors={setFileUploadErrors}
            setLimitReason={setLimitReason}
            setShowLimitModal={setShowLimitModal}
          />
        )}
      </div>

      <label className="text-2xl font-semibold mt-8 mb-3">Number of questions</label>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-3 gap-4 justify-center rounded-lg p-4 bg-white shadow ">
        {numberOfQuestionsList.map((value, index) => (
          <div className="flex flex-col" key={index}>
              <label className="mb-2 text-lg">
                {numberOfQuestionsTitlesList[index]}
              </label>
              <select
                className={`${error && "border-2 border-rose-500"} border-gray-200 border-2 rounded-lg p-2 font-medium shadow outline-none focus:border-gray-400`}
                value={value}
                onChange={(e) => updateNumberOfQuestionsField(index, e.target.value)}
              >
                {createPickerItems(remainingForIndex(index)).map((item) => (
                  <option key={item.value} value={item.value}>
                    {item.label}
                  </option>
                ))}
              </select>

          </div>
        ))}





        <div className="flex flex-col">
          <label className="mb-2 text-lg">Difficulty Level</label>
          <select 
          className="border-2 border-gray-200 px-2 py-2 rounded-lg font-medium shadow "
          onChange={(e) => setDifficulty(e.target.value)}
          value={difficulty}
          >
            <option value="easy">Easy</option>
            <option value="medium">Medium</option>
            <option value="hard">Hard</option>
          </select>
        </div>
      </div>

      <div className="flex justify-center py-12">
        <button
          className={`${!isValid === true ? "text-gray-500" : "text-white"} bg-blue-600 border-2 border-blue-600 py-2.5 px-10 rounded-full font-medium text-lg transition-colors duration-300 ease-in-out
                    hover:bg-blue-700 hover:border-blue-600
                    active:bg-blue-800 active:border-blue-700 active:shadow-inner
                    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
                    shadow-color-lg shadow-blue-400
                    disabled:bg-gray-200 disabled:border-gray-300 disabled:shadow-gray-300 disabled:cursor-not-allowed disabled:text-gray-500`}
          onClick={handleSubmit}
          disabled={!isValid}
        >
          Generate Exam
        </button>
      </div>

    </div>
  );
};

export default ExamInput;


