import { useEffect, useRef } from 'react';

const AnswerInputFieldCorrectCorrection = ({ userAnswer, showHeader }) => {
  const userAnswerRef = useRef(null);

  useEffect(() => {
    handleAutoResize(userAnswerRef.current);
  }, [userAnswer]);

  const handleAutoResize = (element) => {
    if (element) {
      element.style.height = 'auto';

      if (element.scrollHeight > element.clientHeight) {
        element.style.height = `${element.scrollHeight}px`;
        element.style.overflowY = 'auto';
      } else {
        element.style.overflowY = 'hidden';
      }
    }
  };

  const inputStyle = {
    boxSizing: 'border-box',
    maxHeight: '12em',
    resize: 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  };

  const inputWebkitScrollbarStyle = {
    display: 'none', 
  };

  return (
    <div className="flex flex-col items-center">
      {showHeader && <p className="font-medium text-2xl mb-5">Your answer</p>}
      <textarea
        readOnly
        value={userAnswer}
        style={{
          ...inputStyle,
          '::-webkit-scrollbar': inputWebkitScrollbarStyle,
        }}
        ref={userAnswerRef}
        rows={1}
        wrap="soft"
        className="mb-3 p-2 w-full border-2 border-green-400 bg-green-50 rounded-lg shadow-color-lg shadow-green-300 outline-none text-lg"
      />
    </div>
  );
};

export default AnswerInputFieldCorrectCorrection;
