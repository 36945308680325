const NextQuestionButton = ({questionIndex, numberOfQuestions, handleRedirect, handleNextQuestion}) => {
  console.log("this is the question index: ", questionIndex)
  console.log("this is the number of questions: ", numberOfQuestions)


  return (
    <div className="flex justify-center mt-24">

      <button
        className="text-white bg-blue-600 border-2 border-blue-600 py-2.5 px-24 rounded-lg font-medium text-lg transition-colors duration-300 ease-in-out
    hover:bg-blue-700 hover:border-blue-600
    active:bg-blue-800 active:border-blue-700 active:shadow-inner
    focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
    shadow-color-lg shadow-blue-400"
        onClick={questionIndex === numberOfQuestions ? handleRedirect : handleNextQuestion}
      >
        {questionIndex === numberOfQuestions ? "Show results" : "Next question"}
      </button>
     
    </div>
  )
}
export default NextQuestionButton;



// const NextQuestionButton = ({questionIndex, numberOfQuestions, handleRedirect, handleNextQuestion}) => {
//   return (
//     <div className="flex justify-center mt-11">
//       {questionIndex === numberOfQuestions ? (
//         <button className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" onClick={handleRedirect}>
//           Show results
//         </button>
//       ) : (
//         <button  className="text-white bg-gradient-to-br from-green-400 to-blue-600 hover:bg-gradient-to-bl focus:ring-4 focus:outline-none focus:ring-green-200 dark:focus:ring-green-800 font-medium rounded-lg text-sm px-5 py-2.5 text-center me-2 mb-2" onClick={handleNextQuestion}>
//           Next question
//         </button>
//       )}
//     </div>
//   )
// }
// export default NextQuestionButton;