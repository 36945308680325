import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useParams, useNavigate } from "react-router-dom";
import api from "../../api";


const FlashcardSettingsModal = ({ showModal, setShowModal, flashcards, askMode }) => {
  const [userTokens, setUserTokens] = useState(0)
  const [isMobile, setIsMobile] = useState(false);
  const [subscriptionPlan, setSubscriptionPlan] = useState("basic");
  const [userHasStarredCard, setUserHasStarredCard] = useState(false);
  const [userHasIncorrect, setUserHasIncorrect] = useState(false);
  const [questionFilter, setQuestionFilter] = useState("all");
  const [askRandomly, setAskRandomly] = useState(() =>{
    if (sessionStorage.getItem("askRandomly")) {
      return Boolean(sessionStorage.getItem("askRandomly"));
    }
    return false;
  });
  const [currentAskMode, setCurrentAskMode] = useState(() => {
    if (askMode === "write") {
      return "write";
    }
    return "flashcard";
  });
  const [aiFeedback, setAiFeedback] = useState(() => {
    if (sessionStorage.getItem("aiFeedback")) {
      return Boolean(sessionStorage.getItem("aiFeedback"));
    }
    return false;
  })
  const [askType, setAskType] = useState(() => {
    if (sessionStorage.getItem("askType")) {
      return sessionStorage.getItem("askType");
    }
    return "definition";
  });
  

  const { uuid, type } = useParams();
  const navigate = useNavigate();



  useEffect(()=> {
    const urls = [
      "/api/subscription/",
      `/api/flashcards/starred/${uuid}/`,
    ]
    if (askMode === "write") {
      urls.push(`/api/flashcards/write_mode_settings/${uuid}/`, `/api/flashcards/incorrect/write_mode/${uuid}/`)
    } else {
      urls.push(`/api/flashcards/flashcard_mode_settings/${uuid}/`)
    }

    Promise.all(urls.map(url => api.get(url)))
    .then((responses) => {
      setUserTokens(responses[0].data.tokens);
      setSubscriptionPlan(responses[0].data.plan);
      setUserHasStarredCard(responses[1].data.length > 0);
      setAskRandomly(responses[2].data.ask_randomly);
      setAskType(responses[2].data.ask_definition ? "definition" : "term");
      setQuestionFilter(responses[2].data.question_filter);

      if (askMode === "write") {
        const incorrectResponse = responses[3].data;
        console.log("Incorrect settings response", incorrectResponse);
        setUserHasIncorrect(incorrectResponse.length > 0);
      }
    })
    .catch((error) => {
      console.error("Failed to fetch data:", error);
    });
  }, [uuid, askMode]);





  //FIXME: Old code
  // useEffect(()=> {
  //   setUserHasStarredCard(flashcards.some(card => card.starred));
  //   setUserHasIncorrect(flashcards.some(card => card.correct_incorrect));
  //   api.get("/api/subscription/")
  //   .then((response) => {
  //     setUserTokens(response.data.tokens)
  //   })
  //   .catch((error) => {
  //     console.log(error)
  //   })

  //   api.get("/api/flashcards/starred/"+uuid+"/")
  //   .then((response) => {
  //     if (response.data.length > 0) {
  //       setUserHasStarredCard(true);
  //     }
  //   })

  //   if (askMode === "write") {
  //     api.get("/api/flashcards/incorrect/write_mode/"+uuid+"/")
  //     .then((response) => {
  //       if (response.data.length > 0) {
  //         setUserHasIncorrect(true);
  //       }
  //     })
  //   }
  // },[])



  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth <= 640); 
    };
    handleResize(); 
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize); 
    };
  }, []);

  



  const handleRestartFlashcards = () => {
    console.log("restarting")
    const restartPath = askMode === "write" ? `/flashcard/mode/write/${uuid}/all/` : `/flashcard/mode/flashcard/${uuid}/all/`;

    api.put(askMode === "write" ? "/api/flashcards/restart_flashcard_set/" : "/api/flashcards/restart_flashcard_set/flashcard_mode/",
      { uuid: uuid, question_type: "all" })
      .then((response) => {
        console.log("this is the response from the backend", response.data);
        setShowModal(false);

        navigate(restartPath);
        window.location.reload(); 
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };






  const handleCloseModal = () => {
    api.put((askMode === "write" ? "/api/flashcards/write_mode_settings/" + uuid + "/" : "/api/flashcards/flashcard_mode_settings/" + uuid + "/"), { ask_randomly: askRandomly, ask_definition: askType === "definition", question_type: questionFilter})
    .then((response) => {
      if (questionFilter !== type && questionFilter !== "incorrect" && questionFilter !== "starred-incorrect") {
        api.put((askMode === "write" ? "/api/flashcards/restart_flashcard_set/" : "/api/flashcards/restart_flashcard_set/flashcard_mode/"), { uuid: uuid, question_type: questionFilter })
          .then((response) => {
            console.log("\n\nrestart called")
            handleNavigate(questionFilter);
          })
          .catch((error) => {
            console.error("There was an error restarting the set!", error);
          });
      } else if (questionFilter !== type && (questionFilter === "incorrect" || questionFilter === "starred-incorrect")) {
        api.put((askMode === "write" ? "/api/flashcards/write_mode/update_question_index/" : "/api/flashcards/update_flashcard_mode_index/"), { uuid: uuid, question_type: questionFilter, index: 0 }) 
          .then((response) => {
            console.log("\n\nupdate question index called")
            handleNavigate(questionFilter);
          })
          .catch((error) => {
            console.error("There was an error updating the question index!", error);
          });
      } else {
        // If the question filter is the same as the type, simply update settings
        handleNavigate(questionFilter);
      }
    })
    .catch((error) => {
      console.error("Failed to update settings:", error);
    });
  }





  //FIXME: Old code
  // const handleCloseModal = (newMode) => {
  //   console.log("this is the randomly in the handle close", askRandomly)
  //   sessionStorage.setItem("askRandomly", askRandomly);
  //   sessionStorage.setItem("aiFeedback", aiFeedback);
  //   sessionStorage.setItem("askType", askType);
  //   setCurrentAskMode(newMode);
  
  //   let newType = type;
  //   if (questionFilter === "starred") {
  //     newType = "starred";
  //   } else if (questionFilter === "incorrect") {
  //     newType = "incorrect";
  //   } else {
  //     newType = "all";
  //   }
  
  //   if(newMode === "flashcard") {
  //     navigate(`/flashcard/mode/flashcard/${uuid}/${newType}`);
  //   } else {
  //     navigate(`/flashcard/mode/write/${uuid}/${newType}`);
  //   }
  //   window.dispatchEvent(new CustomEvent("modalClosedAndSettingsUpdated"));
  
  //   setShowModal(false);
  // };



  const handleNavigate = (questionFilter) => {
    let newType = "all";  // Default to 'all' if no cases match
    console.log("\n\nthis is the question filter in the settings\n", questionFilter);
    
    switch (questionFilter) {
      case "starred":
        newType = "starred";
        break;
      case "incorrect":
        newType = "incorrect";
        break;
      case "starred-incorrect": 
        newType = "starred-incorrect";
        break;
      default:
        newType = "all";
    }
  
    let path = "/flashcard/mode/flashcard/";
    if (askMode === "write") {
      path = "/flashcard/mode/write/";
    }
    navigate(`${path}${uuid}/${newType}/`);
  
    window.dispatchEvent(new CustomEvent("modalClosedAndSettingsUpdated"));
    setShowModal(false);
  };
  

  // const handleNavigate = (questionFilter) => {
  //   let newType = type;
  //   console.log("\n\nthis is the question filter in the settings\n", questionFilter)
  //   if (questionFilter === "starred" || questionFilter === "starred-incorrect") {
  //     newType = "starred";
  //   } else if (questionFilter === "incorrect") {
  //     newType = "incorrect";
  //   } else if (questionFilter === "starred-incorrect") {
  //     newType = "starred-incorrect";
  //   } else {
  //     newType = "all";
  //   }


  //   let path = "/flashcard/mode/flashcard/";
  //   if (askMode === "write") { 
  //     path = "/flashcard/mode/write/";
  //   }
  //   navigate(`${path}${uuid}/${newType}/`);

  //   window.dispatchEvent(new CustomEvent("modalClosedAndSettingsUpdated"));
  //   setShowModal(false);
  // }


  const handleSwitchMode = () => {
    setShowModal(false);
    if (currentAskMode === "write") {
      navigate(`/flashcard/mode/write/${uuid}/${type}/`);
    } else {
      navigate(`/flashcard/mode/flashcard/${uuid}/${type}/`);
    }
  };



  return showModal ? 
    <div className="fixed inset-0 z-50 flex items-center justify-center bg-black bg-opacity-50">
      <div
        className={`bg-white pt-6  px-10 pb-10 rounded-xl ${
          isMobile ? "w-full h-full" : "w-[750px] sm:mx-auto relative lg:w-[825px]"
        }`}
      >

        <button
          onClick={() => handleCloseModal(currentAskMode)}
          className="absolute top-7 right-10 focus:outline-none"
        >
          <FontAwesomeIcon
            icon={faTimes}
            className="text-gray-500 hover:text-gray-700 text-2xl"
          />
        </button>

        <div className="h-full flex flex-col justify-center items-center">
          <p className="text-xl font-semibold mb-10">
            Flashcard Settings
          </p>
          <div className="flex justify-between items-center w-full ml-2.5">
            <span className="font-medium text-gray-900 dark:text-gray-300">
              Shuffle terms
            </span>
            <label className="inline-flex items-center cursor-pointer">
              <input
                type="checkbox"
                value=""
                className="sr-only peer"
                checked={askRandomly}
                onChange={() => setAskRandomly(!askRandomly)}
              />
              <div
                className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-transparent rounded-full 
                ${
                  askRandomly
                    ? "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"
                    : ""
                } 
                after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-blue-600`}
              ></div>
            </label>
          </div>

          <div className="flex justify-between items-center w-full mt-4 ml-2.5">
            <label className="block font-medium text-gray-900 dark:text-gray-300">
              Answer with
            </label>
            <select className="block border border-gray-300 rounded-lg shadow-sm p-2.5 focus:ring focus:ring-blue-300 focus:ring-opacity-50 focus:border-blue-300 mt-1" defaultValue={askType} value={askType} onChange={e => setAskType(e.target.value)}>
              <option value="definition">Definition</option>
              <option value="term">Term</option>
            </select>
          </div>







        

          <div className="flex justify-between items-center w-full mt-4 ml-2.5">
            <label className="block font-medium text-gray-900 dark:text-gray-300">
              Learn
            </label>
            {(
              (askMode === "flashcard" && !userHasStarredCard && !userHasIncorrect) ||
              (askMode === "write" && !userHasStarredCard && !userHasIncorrect)
            ) ? (
              <span className="block border border-gray-300 rounded-lg shadow-sm p-2.5 mt-1 font-medium">All terms</span>
            ) : (
              <select className="block border border-gray-300 rounded-lg shadow-sm p-2.5 focus:outline-none mt-1" defaultValue={questionFilter} onChange={e => setQuestionFilter(e.target.value)}>
                <option value="all">All terms</option>
                {userHasStarredCard && <option value="starred">Only learn ★-terms</option>}
                {askMode === "write" && userHasIncorrect && <option value="incorrect">Only wrong terms</option>}
              </select>
            )}
          </div>



{/* 
          {userHasStarredCard && (
            <div
              className={`flex justify-between items-center w-full mt-4 ml-2.5 ${
                askIncorrect ? "opacity-50" : ""
              }`}
            >
              <span className="font-medium text-gray-900 dark:text-gray-300">
                Only learn ★-terms{" "}
              </span>
              <label className="inline-flex items-center cursor-pointer">
                <input
                  type="checkbox"
                  value=""
                  className="sr-only peer"
                  checked={askStarred}
                  onChange={() => setAskStarred(!askStarred)}
                  disabled={askIncorrect}
                />
                <div
                  className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-transparent dark:peer-focus:ring-transparent rounded-full 
                  ${
                    askStarred
                      ? "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"
                      : ""
                  }
                  after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all 
                  dark:border-gray-600 peer-checked:bg-blue-600`}
                ></div>
              </label>
            </div>
          )} */}


{/* 
          {currentAskMode === "write" && (
            <div className="mt-4 ml-2.5 w-full">
              <div className="flex justify-between items-center cursor-pointer w-full">
                <span className="font-medium text-gray-900 dark:text-gray-300">AI feedback</span>
                <label className="inline-flex items-center cursor-pointer">
                  <input
                    type="checkbox"
                    value=""
                    className="sr-only peer"
                    checked={aiFeedback}
                    onChange={() => setAiFeedback(!aiFeedback)}
                  />
                  <div
                    className={`relative w-11 h-6 bg-gray-200 peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-transparent dark:peer-focus:ring-transparent rounded-full 
                              ${
                                aiFeedback
                                  ? "peer-checked:after:translate-x-full rtl:peer-checked:after:-translate-x-full peer-checked:after:border-white"
                                  : ""
                              } 
                              after:content-[''] after:absolute after:top-[2px] after:start-[2px] after:bg-white after:border-gray-300 after:border after:rounded-full after:h-5 after:w-5 after:transition-all dark:border-gray-600 peer-checked:bg-green-400`}
                  ></div>
                </label>
              </div>
              <div className="flex justify-start w-full mt-1">
                <div className="w-full" style={{ maxWidth: 'calc(100% - 100px)' }}>
                  <span className="text-sm text-neutral-400">
                    Ignoring spelling errors when marking, and check for understanding rather than a response that is exactly the same as the provided definition.
                  </span>
                </div>
              </div> 
            </div>
          )} */}



          <div className="flex justify-between items-center w-full mt-4 ml-2.5">
            <p className="font-medium text-gray-900 dark:text-gray-300">{currentAskMode === "write" ? "Switch to flashcard mode" : "Switch to write mode"}</p>
            <button 
              className="flex items-center text-blue-600 hover:text-blue-700 font-semibold rounded-lg p-2.5 hover:bg-blue-50 gap-2.5" 
              onClick={handleSwitchMode}
            >
              Start
              <svg className="w-3.5 h-3.5 mr-2" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 14 10">
                <path stroke="currentColor" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M1 5h12m0 0L9 1m4 4L9 9"/>
              </svg>
            </button>
          </div>


          
          <div className="flex justify-start w-full mt-4">
            <button className="p-2.5 hover:bg-green-100 rounded-lg">
              <a href="/pricing/" className="font-semibold text-base text-green-500 hover:text-green-600 underline-offset-2 hover:underline">
                {userTokens} Tokens
              </a>
            </button>
          </div>
													
													

          <div className="flex justify-start w-full">
            <button className="mt-3 text-blue-600 hover:text-blue-600 hover:bg-blue-100 font-semibold rounded-lg p-2.5">
              <a href={"/flashcard/preview/"+uuid+"/"}>
                Edit flashcard set
              </a>
            </button>
          </div>
          <div className="flex justify-start w-full">
            <button className="mt-3 text-red-600 hover:text-red-600 hover:bg-red-100 font-semibold rounded-lg p-2.5" onClick={handleRestartFlashcards}>
              Restart flashcards
            </button>
          </div>


        </div>
      </div>
    </div>
   : null;
};

export default FlashcardSettingsModal;
