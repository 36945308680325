import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useParams } from "react-router-dom";
import ProgressBarComponent from "../components/OtherQuestionComponents/ProgressBarComponent";
import QuestionBox from "../components/OtherQuestionComponents/QuestionBox";
import OptionsComponent from "../components/OtherQuestionComponents/OptionsComponent";
import AnswerSubmitButton from "../components/OtherQuestionComponents/AnswerSubmitButton";
import InputFieldComponent from "../components/OtherQuestionComponents/InputFieldComponent";
import OptionsCorrectionComponent from "../components/SummaryQuestionComponents/OptionsCorrectionComponent";
import NextQuestionButton from "../components/OtherQuestionComponents/NextQuestionButton";
import WrongFooter from "../components/OtherQuestionComponents/WrongFooter";
import CorrectFooter from "../components/OtherQuestionComponents/CorrectFooter";
import PointsFooter from "../components/OtherQuestionComponents/PointsFooter";
import AnswerInputFieldCorrectCorrection from "../components/General/AnswerInputFieldCorrectCorrection";
import AnswerInputFieldIncorrectCorrection from "../components/General/AnswerInputFieldIncorrectCorrection";
import api from "../api";
import LoadingSpinner from "../components/General/LoadingSpinner";



function ExamQuestions() {
  const [questions, setQuestions] = useState([]);
  const [numberOfQuestions, setNumberOfQuestions] = useState(0);
  const [questionIndex, setQuestionIndex] = useState(0);

  const [userAnswer, setUserAnswer] = useState("");
  const [correction, setCorrection] = useState("");
  const [correctIncorrect, setCorrectIncorrect] = useState(null);

  const [pointsForQuestion, setPointsForQuestion] = useState(0);
  const [points, setPoints] = useState(0);
  const [maxPoints, setMaxPoints] = useState(0);
  const [maxPointsUntilNow, setMaxPointsUntilNow] = useState(0);
  const [optionsUserAnswerIndex, setOptionsUserAnswerIndex] = useState(0);


  const [showResponse, setShowResponse] = useState(false);
  const [loadingSite, setLoadingSite] = useState(true);
  const [essayLength, setEssayLength] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const [previousQuestionIndex, setPreviousQuestionIndex] = useState(0);
  const [submitted, setSubmitted] = useState(false);
  const [essayInputEmpty, setEssayInputEmpty] = useState(false);
  const [progressBarQuestionIndex, setProgressBarQuestionIndex] = useState(0);
  const [loadingAnswer, setLoadingAnswer] = useState(false)


  const navigate = useNavigate();

  const { uuid } = useParams();

  sessionStorage.setItem("loadNavbar", "false");

  const getMaxPoints = (currentQuestionType) => {
    if (currentQuestionType === "multipleChoice") {
      return 1;
    } else if (currentQuestionType === "trueOrFalse") {
      return 1;
    } else if (currentQuestionType === "fillInTheBlank") {
      return 2;
    } else if (currentQuestionType === "shortAnswer") {
      return 5;
    } else if (currentQuestionType === "essay") {
      return 10;
    }
  };



  useEffect(() => {
    setLoadingSite(true)
    api.get(`/api/summary_questions/${uuid}/`)
      .then((response) => {
        console.log(response.data);
        console.log("These are the questions: ", response.data.questions);
        console.log("this is the type of the questions: ", typeof response.data.questions);
        setQuestions(response.data.questions);
        setNumberOfQuestions(response.data.questions.length);
        let totalPointsCalc = 0;
        response.data.questions.forEach((question) => {
          totalPointsCalc += getMaxPoints(question.type);
        });
        setMaxPoints(totalPointsCalc); 
        if (response.data.question_index !== 0) {
          if (response.data.question_index === response.data.questions.length) {
            return handleRedirect();
          }
          setPreviousQuestionIndex(response.data.question_index);
          return handleContinueFromSavedPoint(response.data, response.data.question_index);
        }
        setLoadingSite(false)
      })
      .catch((error) => {
        console.log("Error while getting question data:", error);
      });
  }, [])
  
  const handleContinueFromSavedPoint = (questionData, questionIndexBefore) => {
    const totalPointsCalc = questionData.points_list.reduce((a, b) => a + b, 0);
    const totalPointsUntilNowCalc = questionData.questions.reduce((accumulator, currentValue, index) => {
      if (index < questionIndexBefore) {
        return accumulator + currentValue.points;
      }
      return accumulator;
    }, 0); 
    console.log("these are the totalpoitns until now: ", totalPointsUntilNowCalc)
    setMaxPointsUntilNow(totalPointsUntilNowCalc);
    setPoints(totalPointsCalc);
    setShowModal(false);
    setQuestionIndex(questionIndexBefore);

    setProgressBarQuestionIndex(questionIndexBefore);
    setLoadingSite(false)

  };

  const handleRedirect = () => {
    api.put("/api/summary_reset_question_index/", { uuid: uuid })
      .then(() => {
        navigate(`/exam/recap/${uuid}/`);
      })
      .catch((error) => {
        console.log("Error while resetting question index:", error);
      });
  };






  const handleRestart = () => {
    api.put(`/api/delete_summary_fields/${uuid}/`, {})
    .then(() => {
      console.log("Deleted fields");
      setShowModal(false);
      setQuestionIndex(0);
    })
    .catch((error) => {
      console.log("Error while deleting fields:", error);
      setShowModal(false);
      setQuestionIndex(0);
    });
  };
  


  const handleNextQuestion = () => {
    setQuestionIndex((prevIndex) => prevIndex + 1);
    setShowResponse(false);

    setUserAnswer("");
    setCorrection(null);
    setEssayLength(0);
  };




  const handleShowResponse = () => {
    setSubmitted(true);
    if (userAnswer !== "") {
      setLoadingAnswer(true)

      if (questions[questionIndex].type === "multipleChoice") {
        setOptionsUserAnswerIndex(questions[questionIndex].choices.indexOf(userAnswer));
      }


      setCorrectIncorrect(true);
      setCorrection(null);

      api.post("/api/summary_answers/" + uuid + "/", { answer: userAnswer, questionIndex: questionIndex })
        .then(() => {
          api.get("/api/summary_correction/" + uuid + "/" + questionIndex + "/")
            .then((response) => {
              const responseData = response.data;

              setCorrection(responseData.corrections.slice(-1)[0]);
              setPointsForQuestion(responseData.points_list.slice(-1)[0]);
              setPoints(
                (prevPoints) =>
                  prevPoints + responseData.points_list.slice(-1)[0]
              );
              setCorrectIncorrect(responseData.correct_incorrect.slice(-1)[0]);
              setProgressBarQuestionIndex((prevIndex) => prevIndex + 1);
              setLoadingAnswer(false)
              setShowResponse(true);
              setMaxPointsUntilNow((prevPoints) => prevPoints + getMaxPoints(questions[questionIndex].type));
            })
            .catch((error) => {
              console.log("Error while getting correction data:", error);
            });
        })
        .catch((error) => {
          console.log("Error while posting answer:", error);
        });
      setSubmitted(false)
    }
  };

  const handleEssayChange = (event) => {
    const { value } = event.target;
    setEssayLength(value.length);
    if (essayLength <= 40000) {
      setUserAnswer(value);
    }
  };

  useEffect(() => {
    if (submitted && userAnswer === "" && questions[questionIndex].type === "essay") {
      setEssayInputEmpty(true);
    }
    else {
      setEssayInputEmpty(false);
    }
  }, [userAnswer, submitted]);








  if (loadingSite) {
    return (
      <LoadingSpinner />
    )
  }

  return (
    <div style={{ padding: "0 13vw", backgroundColor: "#F6F7FB" }} className="min-h-screen">
      <ProgressBarComponent
        index={questionIndex}
        numberOfQuestions={numberOfQuestions}
      />


      {!showResponse && questions.length !== 0 && (
        <div>
          <QuestionBox
            maxPoints={getMaxPoints(questions[questionIndex].type)}
            question={questions[questionIndex].question}
          />

          {questions[questionIndex].type === "multipleChoice" && (
            <OptionsComponent
              optionsList={questions[questionIndex].choices}
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
              submitted={submitted}
            />
          )}

          {questions[questionIndex].type === "trueOrFalse" && (
            <OptionsComponent
              optionsList={["True", "False"]}
              userAnswer={userAnswer}
              setUserAnswer={setUserAnswer}
              submitted={submitted}
            />
          )}

          {questions[questionIndex].type === "fillInTheBlank" && (
            <InputFieldComponent
              value={userAnswer}
              setValue={setUserAnswer}
              maxWidth="375px"
              placeholder={"Enter answer"}
              submitted={submitted}
            />
          )}
          {questions[questionIndex].type === "shortAnswer" && (
            <InputFieldComponent
              value={userAnswer}
              setValue={setUserAnswer}
              maxWidth="525px"
              placeholder={"Enter answer"}
              submitted={submitted}
            />
          )}

          {questions[questionIndex].type === "essay" && (
            <div>
              <textarea
                className={`w-full mt-3 mb-1.5 p-2.5 h-64 ${essayInputEmpty &&"border-2 border-red-500"} rounded-md focus: outline-none resize-none shadow`}
                placeholder="Enter your essay"
                onChange={handleEssayChange}
                maxLength={40000}
              />
              <p id="charCount" className="text-sm text-gray-500">
                {essayLength} / 40'000 characters
              </p>
              {essayInputEmpty && (
                <p className="text-red-500 text-sm">Essay is required</p>
              )}
            </div>
          )}
          <AnswerSubmitButton
            onClick={handleShowResponse}
            loadingState={loadingAnswer}
            userAnswer={userAnswer}
          />

          <PointsFooter maxPoints={maxPointsUntilNow} currentPoints={points} />
        </div>
      )}

      {showResponse && correctIncorrect === false && correction !== null && (
        <div>
          <QuestionBox
            maxPoints={getMaxPoints(questions[questionIndex].type)}
            question={questions[questionIndex].question}
          />
          {questions[questionIndex].type === "multipleChoice" && (
            <div>
              <OptionsCorrectionComponent
                optionsList={questions[questionIndex].choices}
                incorrectIndex={optionsUserAnswerIndex}
                correctIndex={Number(correction)}
              />
            </div>
          )}
          {questions[questionIndex].type === "trueOrFalse" && (
            <div>
              <OptionsCorrectionComponent
                optionsList={["True", "False"]}
                incorrectIndex={userAnswer === "True" ? 0 : 1}
                correctIndex={userAnswer === "True" ? 0 : 1}
              />
            </div>
          )}
          {questions[questionIndex].type === "fillInTheBlank" && (
            <AnswerInputFieldIncorrectCorrection 
              userAnswer={userAnswer}
              correction={correction}
              correctionHeader={"Possible correct word"}
            />
          )}
          {questions[questionIndex].type === "shortAnswer" && (
            <AnswerInputFieldIncorrectCorrection
              userAnswer={userAnswer}
              correction={correction}
              correctionHeader="Correct answer"
            />
          )}

          {questions[questionIndex].type === "essay" && (
            <AnswerInputFieldIncorrectCorrection
              userAnswer={userAnswer}
              correction={correction}
              correctionHeader="Feedback on your essay"
            />
          )}

          <NextQuestionButton
            questionIndex={questionIndex + 1}
            numberOfQuestions={numberOfQuestions}
            handleRedirect={handleRedirect}
            handleNextQuestion={handleNextQuestion}
          />

          <WrongFooter />
        </div>
      )}

      {showResponse && correctIncorrect === true && (
        <div>
          <QuestionBox
            maxPoints={getMaxPoints(questions[questionIndex].type)}
            question={questions[questionIndex].question}
          />
          {questions[questionIndex].type === "multipleChoice" && (
            <div>
              <OptionsCorrectionComponent
                optionsList={questions[questionIndex].choices}
                incorrectIndex={NaN}
                correctIndex={optionsUserAnswerIndex}
              />
            </div>
          )}

          {questions[questionIndex].type === "trueOrFalse" && (
            <div>
              <OptionsCorrectionComponent
                optionsList={["True", "False"]}
                incorrectIndex={NaN}
                correctIndex={userAnswer === "True" ? 0 : 1}
              />
            </div>
          )}

          {questions[questionIndex].type === "fillInTheBlank" && (
            <AnswerInputFieldCorrectCorrection
              userAnswer={userAnswer}
              showHeader={true}
            />
          )}
          {questions[questionIndex].type === "shortAnswer" && (
            <AnswerInputFieldCorrectCorrection
            userAnswer={userAnswer}
            showHeader={true}
              />
          )}
          {questions[questionIndex].type === "essay" && (
            <AnswerInputFieldCorrectCorrection 
              userAnswer={userAnswer}
              showHeader={true}
            />
          )}

          <NextQuestionButton
            questionIndex={questionIndex + 1}
            numberOfQuestions={numberOfQuestions}
            handleRedirect={handleRedirect}
            handleNextQuestion={handleNextQuestion}
          />
          <CorrectFooter points={pointsForQuestion} />
        </div>
      )}
    </div>
  );
}

export default ExamQuestions;
