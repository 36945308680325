import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { useNavigate } from "react-router-dom";
import { useEffect, useState } from "react";
import QuitModal from "./QuitModal";

const ProgressBarComponent = ({ index, numberOfQuestions }) => {
  const [progress, setProgress] = useState(0);
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();

  sessionStorage.setItem("loadNavbar", "false");

  const handleHomeButtonClicked = () => {
    sessionStorage.setItem("loadNavbar", null);
    navigate("/");
  };

  useEffect(() => {
    index === 0 && setProgress(0);
    index > 0 && setProgress((index / numberOfQuestions) * 100);
  }, [index, numberOfQuestions]);

  console.log("this is the progress", progress);


  const handleModalButtonClick = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowModal(true);
  };

  return (
    <div className="py-6">
      <QuitModal isOpen={showModal} onQuit={handleHomeButtonClicked} onContinue={() => setShowModal(false)} setShowModal={setShowModal} />
      <div className="flex justify-between items-center">
        <div className=" h-7 flex items-center justify-center">
          <button onClick={handleModalButtonClick}>
            <FontAwesomeIcon icon={faTimes} style={{ fontSize: "28px" }} className="text-zinc-600" />
          </button>
        </div>
        <div className="w-full px-20 h-7">
          <div className="w-full bg-gray-200 rounded-full h-7 mb-4 dark:bg-gray-700 ">
            <div className="bg-navbar-green h-7 rounded-full dark:bg-green-500" style={{ width: `${progress}%` }}></div>
          </div>
        </div>
        <div className=" h-7 flex items-center justify-center">
          <div className="text-xl text-zinc-600 font-medium">{index}/{numberOfQuestions}</div>
        </div>
      </div>
    </div>
  );
};

export default ProgressBarComponent;
