import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTimes, faPaste } from '@fortawesome/free-solid-svg-icons';
import Lottie from 'react-lottie';
import animationData from "../../Assets/Lottie/generate-from-file.json";
import api from '../../api';


const UploadTextModal = ({ showModal, setShowModal, type, processData, loading, setLoading, setLimitReason, setShowLimitModal, subscriptionPlan }) => {
  const [text, setText] = useState("");
  const [textLength, setTextLength] = useState(0);
  const [numberOfFlashcards, setNumberOfFlashcards] = useState(1);
  const [error, setError] = useState("");

  const userCharacterLimit = subscriptionPlan === "max" ? 50000 : 10000;

  const numberOfFlashcardOptions = [];
  for (let i = 1; i <= 5; i++) {
    numberOfFlashcardOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }

  for (let i = 10; i <= 50; i += 5) {
    numberOfFlashcardOptions.push(
      <option key={i} value={i}>
        {i}
      </option>
    );
  }






  const uploadText = () =>  {
    if (textLength === 0) {
      setError("Please enter some text.");
      return;
    }
    if (textLength > userCharacterLimit) {
      setLimitReason("text");
      setShowLimitModal(true);
      return;
    }
    setLoading(true);
    if (type === "flashcards") {
      const data = {
        "text": text,
        "number_of_flashcards": numberOfFlashcards
      }
      api.post("/api/flashcards/generate_flashcards_from_text/", data)
      .then((response) => {
        if (!response.data.enough_tokens && response.data.enough_tokens !== undefined) {
          setLimitReason("tokens");
          setShowLimitModal(true);
          setLoading(false);
          return;
        }
        setLoading(false);
        processData(response.data);
      })
      .catch((error) => {
        console.error(error);
      })
    }
  };



  const onTextChange = (e) => {
    const { value } = e.target;
    setTextLength(value.length);
    if (textLength <= userCharacterLimit) {
      setText(value);
    }
  }

  const handleClipboardPaste = () => {
    navigator.clipboard.readText().then(
      clipText => {
        setText(prev => prev +clipText);
        setTextLength(prev => prev + clipText.length);
      }
    );
  };





  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
    rendererSettings: {
      preserveAspectRatio: 'xMidYMid slice'
    }
  };

  return showModal && (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center">
      <div className="rounded-lg shadow-lg w-full relative max-w-5xl" style={{backgroundColor: "#F6F7FB"}}>
        {loading ? (
          <div className='h-[698px] flex flex-col items-center justify-center'>
            <Lottie 
              options={defaultOptions}
              height={500}
              width={500}
            />
            <h1 className='text-xl font-semibold text-purple-500'>Generating {type}...</h1>
          </div>
        ) : (
          <div>
            <div className='px-8 pt-8'>
              <h2 className="text-2xl font-bold">Upload Text</h2>
              <h3 className='mt-2 text-lg'>Copy and paste your content. Your character limit is {userCharacterLimit.toLocaleString()} characters.</h3>

              
              <button className='mb-2 flex flex-row justify-end w-full pr-2' onClick={handleClipboardPaste}>
                <FontAwesomeIcon icon={faPaste} className='text-xl text-blue-600 hover:text-blue-700' /> 
              </button>
              <textarea 
                value={text}
                type="text" 
                className="w-full p-2.5 border-2 border-gray-300 rounded-md resize-none" placeholder="Paste your text here..." 
                onChange={(e) => onTextChange(e)}
                rows={10}
                cols={50}
              />
              <p className={`font-medium ${textLength > userCharacterLimit && "text-red-500"}`}>Character Count: {textLength.toLocaleString()}/{userCharacterLimit.toLocaleString()}</p>
              <p className='text-lg text-red-500 font-medium mt-2'>{error}</p>
              <div className='mt-6'>
                <h2 className='text-xl font-bold'>Number of Flashcards</h2>
                <select 
                  className='border-2 border-gray-300 rounded-lg px-3 py-2.5 mt-3 w-[205px] font-medium bg-gray-50'
                  value={numberOfFlashcards}
                  onChange={(e) => setNumberOfFlashcards(e.target.value)}
                >
                  {numberOfFlashcardOptions}
                
                </select>
              </div>
            </div>
            
            <div className='border-b-2 border-blue-600 mt-8' />


            <div className="flex justify-between mt-4 px-8 pb-8">
              <button className="bg-blue-600 text-white rounded-md px-4 py-2 font-semibold" onClick={() => setShowModal(false)}>Cancel</button>
              <button className="bg-blue-600 text-white rounded-md px-4 py-2 font-semibold" onClick={uploadText}>Generate</button>
            </div>

            <button
              onClick={() => setShowModal(false)}
              className="absolute top-5 right-5 text-2xl hover:text-gray-500 text-gray-800"
            >
            <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
        )}
      </div>
    </div>
  );
};
export default UploadTextModal;