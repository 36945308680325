import { ReactComponent as PartyPopper } from "../../Assets/party_popper.svg";
import ProgressBarCircle from "./../DefinitionQuestionComponents/ProgressbarCircle";

const FlashcardRecapComponent = ({
  numberOfCorrect,
  numberOfIncorrect,
  handleContinue,
  handleReview,
}) => {


  return (
    <div className="mt-8">
      <div className="flex flex-row justify-center items-center">
        <div className="w-7/12 md:w-4/12">
          <h1 className="sm:text-lg md:text-xl lg:text-3xl font-semibold">
            You're doing great! Keep focusing on your tough terms.
          </h1>
        </div>
        <div className="w-3/12 md:w-2/12 flex flex-col items-end">
          <PartyPopper className="w-20 h-20 md:w-28 md:h-28 lg:w-32 lg:h-32" />
        </div>
      </div>
      <div className="flex flex-col justify-center items-center mt-6 mb-8">
        <ProgressBarCircle
          percent={Math.round(
            (numberOfCorrect / (numberOfCorrect + numberOfIncorrect)) * 100
          )}
        />
      </div>
      <div className="flex flex-row justify-center items-center">
        <div className="w-10/12 md:w-6/12">
          <div className="bg-green-100 border-1 border-green-400 rounded-full"
              style={{
                boxShadow: '0 8px 20px -2px rgba(34, 197, 94, 0.5)'  // Increased blur and spread, higher opacity
              }}>
            <div className="flex flex-row justify-between px-4 py-2">
              <p className="justify-start font-semibold text-green-500 text-sm sm:text-md md:text-lg">
                Know
              </p>
              <p className="justify-end font-semibold text-green-500 text-sm sm:text-md md:text-lg">
                {numberOfCorrect}
              </p>
            </div>
          </div>
        </div>
      </div>



      <div className="flex flex-row justify-center items-center" style={{marginTop: "25px"}}>
        <div className="w-10/12 md:w-6/12">
          <div className="border-1 rounded-full"
              style={{
                borderColor: "rgba(230, 132, 0, 1)",
                backgroundColor: "rgba(230, 132, 0, 0.2)",
                boxShadow: '0 8px 20px -2px rgba(230, 132, 0, 0.5)'  // Increased blur and spread, higher opacity
              }}>
            <div className="flex flex-row justify-between px-4 py-2">
              <p className="justify-start font-semibold text-[#cc7500] text-sm sm:text-md md:text-lg">
                Still learning
              </p>
              <p className="justify-end font-semibold text-[#cc7500] text-sm sm:text-md md:text-lg">
                {numberOfIncorrect}
              </p>
            </div>
          </div>
        </div>
      </div>

      {numberOfIncorrect === 0 ? (
        <div className="mt-12 flex flex-col items-center">
          <button
            style={{ boxShadow: '0 20px 30px -5px rgba(59, 130, 246, 0.5), 0 10px 15px -5px rgba(59, 130, 246, 0.3)' }}
            className="rounded-lg transition w-10/12 md:w-6/12 bg-blue-600 px-3.5 py-2.5 text-sm md:text-md lg:text-lg font-semibold text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-60"
            onClick={handleContinue}
          >
            Continue
          </button>
        </div>
      ) : (
        <div className="mt-12 flex flex-col items-center">
          <button
            style={{ boxShadow: '0 20px 30px -5px rgba(59, 130, 246, 0.5), 0 10px 15px -5px rgba(59, 130, 246, 0.3)' }}
            className="rounded-lg transition w-10/12 md:w-6/12 bg-blue-600 px-3.5 py-2.5 text-sm md:text-md lg:text-lg font-semibold text-white hover:bg-blue-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-blue-600 disabled:opacity-60"
            onClick={handleReview}
          >
            Review {numberOfIncorrect} terms
          </button>
        </div>
      )}
    </div>
  );
};
export default FlashcardRecapComponent;
