import { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faGear } from "@fortawesome/free-solid-svg-icons";
import QuitModal from "../OtherQuestionComponents/QuitModal";
import FlashcardSettingsModal from "./FlashcardSettingsModal";

const FlashcardModeProgressbar = ({ index, numberOfQuestions, flashcards, showSettings, handleNavigateBack }) => {
  const [progress, setProgress] = useState(0);
  const [showQuitModal, setShowQuitModal] = useState(false);
  const [showSettingsModal, setShowSettingsModal] = useState(false);
  sessionStorage.setItem("loadNavbar", "false");

  const handleHomeButtonClicked = () => {
    sessionStorage.setItem("loadNavbar", null);
    handleNavigateBack();
  };

  useEffect(() => {
    index === 0 && setProgress(0);
    index > 0 && setProgress((index / numberOfQuestions) * 100);
  }, [index, numberOfQuestions]);

  const handleQuitModalButtonClicked= (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowQuitModal(true)
  }

  const handleSettingsModalButtonClicked = (event) => {
    event.preventDefault();
    event.stopPropagation();
    setShowSettingsModal(true)
  }
  return (
    <div className="py-6">
      <QuitModal
        isOpen={showQuitModal}
        onQuit={handleHomeButtonClicked}
        onContinue={() => setShowQuitModal(false)}
      />
      <FlashcardSettingsModal showModal={showSettingsModal} setShowModal={setShowSettingsModal} flashcards={flashcards} askMode={"flashcard"} />
      <div className="flex justify-between items-center">
        <div className="w-3/12 h-7 flex items-center justify-center">
          <button onClick={(event) => handleQuitModalButtonClicked(event)}>
            <FontAwesomeIcon
              icon={faTimes}
              style={{ fontSize: "28px" }}
              className="text-zinc-600"
            />
          </button>
        </div>
        <div className="w-6/12 h-5 md:h-7 rounded-full">
          <div className="w-full bg-gray-200 rounded-full h-5 md:h-7 mb-4 dark:bg-gray-700 overflow-hidden">
            <div
              className="bg-green-400 h-5 md:h-7 dark:bg-green-500 rounded-full"
              style={{
                width: `${progress}%`,
                transition: "width 0.5s ease-out"
              }} 
            />
          </div>
        </div>
        {showSettings ? (
          <div className="w-3/12 h-7 flex items-center justify-center">
            <button className="text-xl text-zinc-600" onClick={(event) => handleSettingsModalButtonClicked(event)}>
              <FontAwesomeIcon icon={faGear} style={{fontSize:"21px"}} className="text-zinc-600"/>
            </button>
          </div>
        ): (
          <div className="w-3/12 h-7 flex items-center justify-center"></div>
        )}

      </div>
    </div>
  );
};

export default FlashcardModeProgressbar;