import { useEffect, useState } from "react";
import api from "./../api";
import { Link, useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPen, faBug } from "@fortawesome/free-solid-svg-icons";
import ConfirmDeletionModal from "./../components/ConfirmDeletionModal";
import Cookies from "js-cookie";
import { notify } from "../providers/NotificationProvider";


const Account = () => {
  const [googleAccount, setGoogleAccount] = useState(false);
  const [email, setEmail] = useState("");
  const [passwordEdit, setPasswordEdit] = useState(false);
  const [hasStripeAccount, setHasStripeAccount] = useState(false);
  const [customerPlan, setCustomerPlan] = useState("");
  const [userLanguage, setUserLanguage] = useState("")
  const [oldUserLanguage, setOldUserLanguage] = useState("")
  const [showSaveLanguage, setShowSaveLanguage] = useState(false);
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);
  const [confirmDeletionModalText, setConfirmDeletionModalText] = useState("");
  const [confirmDeletionModalHeader, setConfirmDeletionModalHeader] = useState("");
  const [confirmDeletionButtonLabel, setConfirmDeletionButtonLabel] = useState("");
  const [confirmDeletionType, setConfirmDeletionType] = useState(""); 


  const navigate = useNavigate();


  useEffect(() => {
    const urls = [
      "/api/auth/account-type/",
      "/api/auth/user/",
      "/api/payment/subscriptions/",
      "/api/userinfo/"
    ]

    Promise.all(urls.map(url => api.get(url)))
    .then((responses) => {
      console.log(responses);
      setGoogleAccount(responses[0].data.social_account);
      console.log("This is the google account response: ", responses[0].data.social_account);
      console.log("(!responses[0].data.social_account): ", (!responses[0].data.social_account));
      if (!responses[0].data.social_account) {
        setPasswordEdit(true);
      }
      setEmail(responses[1].data.email);
      if (responses[2].data.customer_id !== "") {
        setHasStripeAccount(true);
        setCustomerPlan(responses[2].data.subscription_tier);
      }
      setUserLanguage(responses[3].data.language);
      setOldUserLanguage(responses[3].data.language);
    })
    .catch((errors) => {
      console.error(errors);
    });


  }, []);





  const onLanguageChange = (event) => {
    const newLanguage = event.target.value;
    setUserLanguage(newLanguage);
    if (newLanguage !== oldUserLanguage) {
      saveLanguageToDatabase(newLanguage);
    }
  };

  const saveLanguageToDatabase = (language) => {
    api.put("/api/userinfo/", { language: language })
      .then((response) => {
        console.log("Language updated:", response.data);
        setOldUserLanguage(language);
      })
      .catch((error) => {
        console.error("Failed to save language:", error);
      });
  };

  const onLogout = () => {
    setConfirmDeletionButtonLabel("Logout");
    setConfirmDeletionModalHeader("Logout");
    setConfirmDeletionModalText("Are you sure you want to logout from your account?");
    setConfirmDeletionType("logout");
    setShowConfirmDeletionModal(true);
  };

  const onDeleteAccount = () => {
    setConfirmDeletionButtonLabel("DELETE ACCOUNT");
    setConfirmDeletionModalHeader("Delete Account");
    setConfirmDeletionModalText("Are you sure you want to delete your account? This action cannot be undone.");
    setConfirmDeletionType("delete");
    setShowConfirmDeletionModal(true);
  };

  const handleDeleteAccount = () => {
    api.delete('/api/auth/delete_user/')
    .then((response) => {
      console.log(response.data);
      Cookies.remove('accessToken');
      Cookies.remove('refreshToken');
      notify({message: "Your account has been deleted!", type: "success"});
      window.location.href = '/';
    })
    .catch((error) => {
      console.error(error);
    });
  };

  const handleLogout = () => {
    Cookies.remove('accessToken');
    Cookies.remove('refreshToken');
    notify({message: "You have been logged out", type: "success"});
    window.location.href = '/';
  };





  
  


  return (
    <div style={{ backgroundColor: "#F6F7FB",}}>
      <ConfirmDeletionModal showModal={showConfirmDeletionModal} setShowModal={setShowConfirmDeletionModal} text={confirmDeletionModalText} header={confirmDeletionModalHeader} buttonLabel={confirmDeletionButtonLabel} handleDelete={confirmDeletionType === "logout" ? handleLogout : handleDeleteAccount} />
      <main className="max-w-5xl flex justify-center items-center flex-col" style={{ margin: "0px auto", padding: "60px 20px", }}>
        <div className="flex justify-between w-full items-center mb-7">
          <h1 className="text-4xl font-semibold">Your account</h1>
        </div>
        <div className="p-8 w-full rounded-3xl mb-5 shadow bg-white">
          <p className="text-2xl font-semibold">Your details</p>
          <div className="py-4">
            <p className="font-medium text-neutral-500 mb-1">Your email address</p>
            <p className="font-semibold">{email}</p>
          </div>
          {passwordEdit && (
            <div className="pb-4 flex justify-between items-center">
              <div>
                <p className="font-medium text-neutral-500 mb-1">Your password</p>
                <p className="font-semibold text-lg">•••••••••••••</p>
              </div>
              <button className="text-blue-600 hover:text-blue-700 focus:outline-none" onClick={() => navigate("/changepassword/")}>
                <FontAwesomeIcon icon={faPen}/>
              </button>
            </div>
          )}
          <div className="border-b-2 border-neutral-200 mt-2" />

        <div className="pt-4 flex-row">
          <p className="font-medium text-neutral-500 mb-1 pb-2">Language for the <span className="font-bold">feedback</span> and <span className="font-bold">exams</span></p>

          <select className="font-semibold text-md p-2.5 pr-20 shadow border rounded-lg focus:outline-none" onChange={(event) => onLanguageChange(event)} value={userLanguage}>
            <option value={"en"}>English 🇺🇸</option>
            <option value={"de"}>Deutsch 🇩🇪</option>
          </select>


        </div>

        </div>
        <div className="p-8 w-full rounded-3xl mb-5 shadow bg-white">
          <p className="text-2xl font-semibold mb-3">Your subscription</p>
          {hasStripeAccount ? (
            <div className="py-2 flex justify-between items-center">
              <div>
                <p className="font-medium text-sm text-neutral-400 mb-1">Your plan</p>
                <p className="font-semibold">{customerPlan}</p>
              </div>
              <Link to="https://billing.stripe.com/p/login/bIYg1haUscJZ6wE4gg" className="text-sm hover:bg-blue-500 transition font-semibold leading-6 text-white bg-blue-600 rounded-full px-5 py-2">Manage</Link>
            </div>
          ):(
            <div className="flex flex-col items-center justify-center">
              <p className="text-xl font-medium">You're not subscribed to Study Buddy.</p>
              <p className="text-lg mt-2.5">Upgrade to get ∞ tokens & other max feature</p>
              <button className="bg-green-500 hover:bg-green-600 text-lg text-white rounded-lg text-center py-2 w-1/2 mt-4 font-semibold" onClick={() => navigate("/pricing/")}>Upgrade</button>
            </div>
          )}
          
        </div>
        <div className="p-8 w-full rounded-3xl mb-5 shadow bg-white">
          <p className="text-2xl font-semibold mb-3">Support</p>
          <p className="text-lg font-semibold text-neutral-600 mb-3">
            Did you encounter any unusual behavior or issues? Let us know by reporting a bug below.
          </p>
          <button className="flex flex-row items-center p-2 hover:bg-blue-200 rounded-lg">
            <FontAwesomeIcon icon={faBug} className="text-blue-600 text-xl mr-2"/>
            <span className="text-start font-semibold text-lg text-blue-600">Report a bug</span>
          </button>

          <div className="border-neutral-300 mt-6" style={{borderBottomWidth: 1.75}} />
          
          <p className="mt-6 mb-3 text-2xl font-semibold">Privacy Policy</p>
          <a className="text-lg text-blue-600 font-medium hover:text-blue-700" href="https://www.privacypolicies.com/live/9de5d83b-002e-4a23-a437-cc02c5e46e22">Read our Privacy Policy</a>

          <div className="border-neutral-300 mt-6" style={{borderBottomWidth: 1.75}} />

          <p className="mt-6 mb-3 text-2xl font-semibold">Terms Of Service</p>
          <a className="text-lg text-blue-600 font-medium hover:text-blue-700" href="https://studybuddyai.me/terms-of-service">Terms Of Service</a>
        </div>

        <div className="p-8 w-full rounded-3xl border-2 border-red-400 mb-5 bg-white shadow-color-lg shadow-red-300">
          <p className="text-2xl font-semibold mb-3">Logout</p>
          <p className="text-lg font-semibold text-neutral-600 mb-3">
            Do you want to logout from your account?
          </p>
          <button className="flex flex-row items-center p-2 hover:bg-red-200 rounded-lg" onClick={onLogout}>
            <span className="text-start font-semibold text-lg text-red-500">Logout</span>
          </button>

          <div className="border-neutral-300 mt-6" style={{borderBottomWidth: 1.75}} />

          <p className="text-2xl font-semibold mb-3 mt-6">Delete Account</p>
          <p className="text-lg font-semibold text-neutral-600 mb-3">
            Are you sure you want to delete your account? This action cannot be undone.
          </p>
          <button className="flex flex-row items-center p-2 hover:bg-red-200 rounded-lg" onClick={onDeleteAccount}>
            <span className="text-start font-semibold text-lg text-red-500">DELETE ACCOUNT</span>
          </button>
        </div>
      </main>
    </div>
  );
}
export default Account;