import useAxiosGet from "../hooks/useAxiosGet";
import { useEffect, useState, useRef } from "react";

const ProfileDropDown = ({ handleLogoutButton, showProfileDropDown, setShowProfileDropDown }) => {
  const [userName, setUserName] = useState("");
  const [email, setEmail] = useState("");
  const dropdownRef = useRef(null);

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShowProfileDropDown(false);
    }
  };

  useEffect(() => {
    document.addEventListener("click", handleClickOutside);

    return () => {
      document.removeEventListener("click", handleClickOutside);
    };
  }, []);

  const { data, error } = useAxiosGet("/api/auth/user/");

  useEffect(() => {
    if (data) {
      console.log(data);
      setUserName(data.username);
      setEmail(data.email);
    }
    if (error) {
      console.error(error);
    }
  }, [data, error]);

  if (!showProfileDropDown) {
    return null;
  }

  return (
    <div
      ref={dropdownRef}
      className="absolute top-full right-0 z-50 mt-8 max-w-md overflow-hidden rounded-2xl bg-white shadow-lg ring-1 ring-neutral-900/5 opacity-100 translate-y-0"
    >
      <div className="w-[300px] p-4">
        <div className="flex flex-col items-center gap-3">
          <div className="flex items-center flex-col gap-1 mb-3">
            <p className="text-center text-lg font-semibold">{userName}</p>
            <div>
              <p className="text-center text-sm font-medium text-neutral-400">
                {email}
              </p>
            </div>
          </div>
          <div className="rounded-xl overflow-hidden bg-neutral-100 flex flex-col w-full">
            <a
              href="/account/"
              className="px-4 py-3 text-sm hover:bg-neutral-200 transition flex items-center gap-4 border-b border-neutral-200"
            >
              Your account
            </a>
            <button
              className="px-4 py-3 text-sm hover:bg-neutral-200 transition flex items-center gap-4 border-b border-neutral-200 last:border-b-0"
              onClick={handleLogoutButton}
            >
              Sign out
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProfileDropDown;