import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import PointsBarComponent from "../components/QuestionRecap/PointsBarComponent";
import api from "./../api";
import AnswerInputFieldCorrectCorrection from "../components/General/AnswerInputFieldCorrectCorrection";
import AnswerInputFieldIncorrectCorrection from "../components/General/AnswerInputFieldIncorrectCorrection";
import LoadingSpinner from "../components/General/LoadingSpinner";



const GrammarRecap = () => {
  const [questionList, setQuestionList] = useState([]);
  const [answerList, setAnswerList] = useState([]);
  const [correctIncorrectList, setCorrectIncorrectList] = useState([]);
  const [corrections, setCorrections] = useState([]);
  const [maxPoints, setMaxPoints] = useState(0);
  const [points, setPoints] = useState(0);
  const [pointsList, setPointsList] = useState([]);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();
  const { uuid } = useParams();




  useEffect(() => {
    setLoading(true);
    api.get("/api/grammar_questions/" + uuid + "/")
      .then((response) => {
        setQuestionList(response.data.questions);
        setAnswerList(response.data.user_answers);
        setCorrections(response.data.corrections);
        setPointsList(response.data.points_list);
        setCorrectIncorrectList(response.data.correct_incorrect);
        setMaxPoints(response.data.questions.length);
        setPoints(response.data.points_list.reduce((a, b) => a + b, 0));
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);




  // const { data, error } = useAxiosGet("/api/grammar_questions/" + uuid + "/");
  // useEffect(() => {
  //   if (data) {
  //     console.log(data);
  //     setQuestionList(data.questions);
  //     setAnswerList(data.user_answers);
  //     setCorrections(data.corrections);
  //     setPointsList(data.points_list);
  //     setCorrectIncorrectList(data.correct_incorrect);
  //     setMaxPoints(data.questions.length);
  //     setPoints(data.points_list.reduce((a, b) => a + b, 0));
  //     setLoading(false);
  //   }
  //   if (error) {
  //     console.error(error);
  //   }
  // }, [data, error]);



  const handleRestartButtonClicked = () => {
    api.put(`/api/delete_grammar_fields/${uuid}/`, {})
    .then(() => {
      console.log("Deleted fields");
      navigate("/grammar/questions/"+ uuid +"/");
    })
    .catch((error) => {
      console.log("Error while deleting fields:", error);
    });
  };

  if (loading) {
    return <LoadingSpinner />;
  }

  return (
    <div className="min-h-screen">
      {!loading && (
        <div style={{ backgroundColor: "#F6F7FB" }} className="px-6">
          <PointsBarComponent points={points} maxPoints={maxPoints} handleExitButtonClicked={() => navigate("/")} handleRepeatButtonClicked={handleRestartButtonClicked} />
          {questionList.map((question, index) => (
            <div className="flex justify-center items-center" key={index}>
              <div className={`max-w-[735px] w-full bg-white rounded-2xl mb-8 p-8 shadow-color-lg ${correctIncorrectList[index] ? "shadow-green-300" : "shadow-red-300"}`}>
                <p className="text-xl mb-4">{question}</p>
                <p className="text-lg text-neutral-700 mb-4 font-medium">Points: {pointsList[index]}</p>
                {correctIncorrectList[index] ? (
                  <AnswerInputFieldCorrectCorrection
                    userAnswer={answerList[index]}
                    showHeader={true}
                  />
                ) : (
                  <AnswerInputFieldIncorrectCorrection
                    userAnswer={answerList[index]}
                    correction={corrections[index]}
                    correctionHeader={"Feedback"}
                  />
                )}

              </div>
            </div>
          ))}
          <div className="flex justify-center mt-12 pb-24">
            <button
              type="button"
              className="text-white bg-blue-600 border-2 border-blue-600 py-2.5 px-24 rounded-lg font-medium text-lg transition-colors duration-300 ease-in-out
              hover:bg-blue-700 hover:border-blue-600
              active:bg-blue-800 active:border-blue-700 active:shadow-inner
              focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
              shadow-color-lg shadow-blue-400"
              onClick={handleRestartButtonClicked}
              >
                Repeat exam
            </button>
          </div>
        </div>
      )}
    </div>
  );
};
export default GrammarRecap;

