import api from "./../api";
import { useEffect, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus, faTrash } from "@fortawesome/free-solid-svg-icons";
import { useNavigate, useParams } from "react-router-dom";
import { notify } from "../providers/NotificationProvider";
import ConfirmDeletionModal from "../components/ConfirmDeletionModal";

const AllPage = () => {
  const [loading, setLoading] = useState(true);
  const [selectedBoxIndex, setSelectedBoxIndex] = useState(null);
  const [data, setData] = useState([]);
  const [deleteSetUuid, setDeleteSetUuid] = useState(null); 
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);


  const { type } = useParams();

  const navigate = useNavigate();

  const handleRepeatButtonClicked = (index) => {
    const url = type === "grammar" ? "/api/delete_grammar_fields/" : "/api/delete_summary_fields/";
    const redirectUrl = type === "grammar" ? "/grammar/questions/" : "/exam/questions/";
    api.put(`${url}${data[index].uuid}/`,{})
      .then(() => {
        console.log("Deleted fields");
        navigate(redirectUrl + data[index].uuid + "/");
      })
      .catch((error) => {
        console.log("Error while deleting fields:", error);
      });
  };

  useEffect(() => {
    setSelectedBoxIndex(null);
    setLoading(true);
    const url = type === "grammar" ? "/api/grammar_title_uuid/" : "/api/summary_title_uuid/";
    api.get(url)
      .then((response) => {
        console.log(response.data);
        setData(response.data);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
      });
  }, [type]);

  const handleBoxClick = (index) => {
    if (data[index].has_started === true) {
      navigate((type === "grammar" ? "/grammar/questions/" : "/exam/questions/") + data[index].uuid);
    }
    else {
      setSelectedBoxIndex(index === selectedBoxIndex ? null : index);
    }
  };

  const handleDeleteSet = () => {
    api.delete((type === "grammar" ? "/api/grammar/delete/" : "/api/summary/delete/") + deleteSetUuid + "/")
    .then((response) => {
      console.log(response.data);
      setSelectedBoxIndex(null);
      const updatedData = data.filter(item => item.uuid !== deleteSetUuid);
      setData(updatedData);
      notify({message: "Set deleted successfully!", type: "success"});
    })
    .catch((error) => {
      console.error(error);
    });
    
    console.log((type === "grammar" ? "/api/grammar/delete/" : "/api/summary/delete/") + deleteSetUuid + "/");
  }




  return (
    <div style={{ padding: "0 13vw", backgroundColor: "#F6F7FB" }} className="min-h-screen">
      {!loading && (
        <div>
          <ConfirmDeletionModal 
            showModal={showConfirmDeletionModal} 
            setShowModal={setShowConfirmDeletionModal} 
            handleDelete={handleDeleteSet} 
            header={"Delete Set"} 
            text={"Are you sure you want to delete the set? This action cannot be undone."} buttonLabel={"Delete"} 
          />
          <div className="w-full flex justify-between items-center py-10">
            <h1 className="text-3xl font-semibold mb-0 flex items-center">
              {type === "grammar" ? "Grammar exercises library" : "Exam library"}
            </h1>
            <button
              className="text-blue-600 hover:text-blue-700 flex items-center px-3 py-2 rounded-lg transition duration-300 ease-in-out hover:bg-blue-200"
              onClick={() => navigate(type === "grammar" ? "/grammar/input/" : "/exam/input/")}
            >
              <FontAwesomeIcon
                icon={faPlus}
                className="text-2xl mr-2"
                color="rgb(37 99 235)"
              />
              <span className="text-xl text-blue-600 font-medium">Create</span>
            </button>
          </div>

          <div className="grid gird-cols-1 md:grid-cols-1 lg:grid-cols-2 gap-4">
            {data.map((set, index) => (
              <div key={index}>
                <div
                  className={`bg-white shadow hover:border-blue-600 transition duration-300 ease-in-out transform hover:scale-105  border-gray-300 rounded-xl p-4 flex flex-col items-start ${
                    selectedBoxIndex !== index ? "hover:bg-gray-100" : ""
                  }`}
                  onClick={() => handleBoxClick(index)}
                >
                  <div className='w-full flex flex-row justify-between items-center mb-3 '>
                    <h3 className="font-semibold text-xl items-center">{set.title}</h3>
                    {selectedBoxIndex === index && (
                      <button 
                        className="text-red-500 hover:text-red-700 text-lg"
                        onClick={(e) => {
                          e.stopPropagation();
                          setDeleteSetUuid(set.uuid);
                          setShowConfirmDeletionModal(true);
                        }}
                      >
                        <span className='mr-2 font-semibold'>Delete</span>
                        <FontAwesomeIcon icon={faTrash} /> 
                      </button>
                    )}
                  </div>
                  <div className="rounded-full bg-gray-200 px-2 py-1 text-sm font-medium">
                    Questions: {set.number_of_questions}
                  </div>

                  <label className="w-full font-semibold mb-2 mt-4 text-sm">
                    {set.performance}% learned
                  </label>
                  <div className="h-5 bg-gray-200 rounded-full w-full">
                    <div
                      className={`bg-green-400 h-5 rounded-full`}
                      style={{ width: `${set.performance}%` }}
                    ></div>
                  </div>

                  {selectedBoxIndex === index && (
                    <div className="flex justify-between gap-3 w-full mt-3">
                      <button
                        className="flex-grow p-2.5 rounded-lg text-white bg-blue-600 hover:bg-blue-700 text-center font-medium w-1/2"
                        onClick={() => handleRepeatButtonClicked(index)}
                      >
                        Repeat {type === "grammar" ? "exercises" : "exam"}
                      </button>
                      <button
                        className="flex-grow p-2.5 rounded-lg text-white bg-blue-600 hover:bg-blue-700 text-center font-medium w-1/2"
                        onClick={() =>
                          navigate((type === "grammar" ? "/grammar/recap/" : "/exam/recap/") + set.uuid)
                        }
                      >
                        Show recap
                      </button>
                    </div>
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      )}
      <div className="h-24" />
    </div>
  );
};

export default AllPage;
