import { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate, Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faArrowLeft, faUser } from "@fortawesome/free-solid-svg-icons";
import useWindowSize from "../hooks/useWindowSize";
import AuthenicationModal from "./OtherQuestionComponents/AuthenticationModal";
import Cookies from "js-cookie";
import ProfileDropDown from "./ProfileDropDown";
import api from "../api";
import { useAuth } from "../router/isAuthenticated";

export default function NavbarComponent() {
  const [tokens, setTokens] = useState(0);
  const [showUpgrade, setShowUpgrade] = useState(false);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [showAuth, setShowAuth] = useState(true);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [showProfileDropDown, setShowProfileDropDown] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth();

  const { width } = useWindowSize();



  const hideNavbarPaths = [
    /\/exam\/questions\/[0-9a-fA-F-]+\//,
    /\/flashcard\/mode\/write\/[0-9a-fA-F-]+\/\w+\/?/,
    /\/flashcard\/mode\/flashcard\/[0-9a-fA-F-]+\/\w+\/?/,
    /\/grammar\/questions\/[0-9a-fA-F-]+\//,
    /register/,
    /confirm-email/,
    /login/,
    /\/password\/reset\/?/,
    /callback/,
    /changepassword/,
    /set/
  ];
  

  const hideNavbar = hideNavbarPaths.some((pattern) =>
    pattern.test(location.pathname)
  );


  const onProductRedirect = (productName) => {
    if (isAuthenticated !== null) {
      if (productName === "exam") {
        CheckIfProductEmpty("/api/summary_title_uuid/", "exam");
      }
      else if (productName === "flashcard") {
        CheckIfProductEmpty("/api/flashcards/flashcard_set_title_uuid/", "flashcard"); 
      }
      else if (productName === "grammar") {
        CheckIfProductEmpty("/api/grammar_title_uuid/", "grammar");
      }
    }
    else {
      setShowAuthModal(true);
    }
  }





  
  useEffect(() => {
    if (isAuthenticated === true) {
      setShowAuth(false);
      api.get("/api/subscription/")
        .then((response) => {
          console.log("this is the response from the subscription",response.data);
          setTokens(response.data.tokens);
          if (response.data.plan === "basic") {
            setShowUpgrade(true);
          }
        })
        .catch((error) => {
          console.error(error);
        });
    }
  }, [location.pathname, isAuthenticated]);




  
  const CheckIfProductEmpty = (link, productName) => {
    api.get(link)
    .then((response) => {
      const data = response.data;
      console.log("this is the data", data);
      if (data.length === 0) {
        console.log("the lenght is 0")
        if (productName === "exam") {
          navigate("/exam/input/");
        }
        else if (productName === "flashcard") {
          navigate("/flashcard/input/");
        }
        else if (productName === "grammar") {
          navigate("/grammar/input/");
        }
      }
      else {

        if (productName === "exam") {
          navigate("/all/exam/");
        }
        else if (productName === "flashcard") {
          navigate("/flashcard/all/");
        }
        else if (productName === "grammar") {
          navigate("/all/grammar/");
        }
      }


    })
    .catch((error) => {
      console.error(error);
    });
  }







  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);
  };


  const handleLogoutButton = () => {
    api.post("/api/auth/logout/")
      .then((response) => {
          console.log("successfully logged out")
          window.location.href = "/";
          Cookies.remove("accessToken");
          Cookies.remove("refreshToken");
          sessionStorage.removeItem("isAuthenticated");
          })
          .catch((error) => {
          console.error("Error fetching logout data:", error);
          });
      };


  if (hideNavbar) {
    return null;
  }

  return (
    <>
      <AuthenicationModal showModal={showAuthModal} setShowModal={setShowAuthModal} homeRedirect={false} />

      

      {isMenuOpen && width < 640 && (
        <div className="fixed inset-0 z-50 bg-gray-900 bg-opacity-50 transition-opacity">
          <div className="fixed inset-0 z-50 bg-white overflow-y-auto transition-transform duration-300 ease-in-out transform translate-x-0 sm:translate-x-0">
            <div className="flex justify-start p-4">
              <button
                type="button"
                className="inline-flex items-start justify-start p-2.5 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                onClick={toggleMenu}
              >
                <FontAwesomeIcon icon={faArrowLeft} style={{ fontSize: "20px" }} />
              </button>
            </div>
            <div className="flex flex-col items-start gap-4 p-4">
              <Link
                to="/exam/input/"
                className="hover:bg-gray-100 p-2.5 rounded-md text font-medium"
                onClick={toggleMenu}
              >
                Exam AI
              </Link>
              <a
                href="/flashcard/input/"
                className="hover:bg-gray-100 p-2.5 rounded-md text font-medium"
                onClick={toggleMenu}
              >
                Flashcard AI
              </a>
              <a
                href="/grammar/input/"
                className="hover:bg-gray-100 p-2.5 rounded-md text font-medium"
                onClick={toggleMenu}
              >
                Grammar AI
              </a>
              <div
                className="w-full bg-gray-300"
                style={{ height: "2px" }}
              ></div>
              {showAuth ? (
                <div>
                  <button className="font-bold text-blue-600 hover:bg-blue-50 p-2.5 rounded-lg" onClick={() => {setShowAuthModal(true); setIsMenuOpen(false);}}>Login</button>
                </div>
              ) : (
                <div className="flex flex-col items-start space-y-3">
                  {showUpgrade && (
                    <button
                      onClick={() => {
                        navigate("/pricing/");
                        toggleMenu();
                      }}
                      className="text-blue-500 font-semibold p-2.5 my-2 rounded-lg hover:bg-blue-50"
                    >
                      Upgrade to Pro
                    </button>
                  )}
                  <button className="p-2.5 hover:bg-green-100 rounded-lg">
                    <a
                      href="/pricing/"
                      className="font-semibold text-base text-green-400 hover:text-green-400 underline-offset-2 hover:underline"
                    >
                      {tokens} Tokens
                    </a>
                  </button>

                  <button className="px-2.5 p-2.5 font-medium hover:bg-gray-100 rounded-lg" onClick={() =>navigate("/account")}>Account</button>
                  <button className="px-2.5 p-2.5 text-red-500 font-medium hover:bg-red-100 rounded-lg" onClick={handleLogoutButton}>Sign out</button>
                </div>
              )}
              
            </div>
          </div>
        </div>
      )}

      <nav className="py-4 border-y-2 border-black">
        <div
          className="flex justify-between items-center"
          style={{ padding: "0 13vw" }}
        >
          {/* Company Icon */}
          <div
            className="flex-shrink-0 flex items-center"
            onClick={() => navigate("/")}
          >
            <img
              className="h-8 w-auto"
              src="https://tailwindui.com/img/logos/workflow-mark-indigo-500.svg"
              alt="Workflow"
            />
          </div>

          {/* Hamburger Menu */}
          <div className="sm:hidden ml-auto">
            <button
              type="button"
              className="items-end justify-end p-2 rounded-md text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
              onClick={toggleMenu}
            >
              <FontAwesomeIcon icon={faBars} />
            </button>
          </div>

          {/* other Navigation Links */}
          
            {width >= 640 && (
              <>
                <div className={`sm:flex items-center ${isMenuOpen ? "hidden" : "flex"} items-center`}>
                  <button
                    onClick={() => onProductRedirect("exam")}
                    className="hover:bg-gray-100 px-3 py-2 rounded-md text font-medium"
                  >
                    Exam AI
                  </button>
                  <button
                    onClick={() => onProductRedirect("flashcard")}
                    className="hover:bg-gray-100 px-3 py-2 rounded-md text font-medium"
                  >
                    Flashcard AI
                  </button>
                  <button
                    onClick={() => onProductRedirect("grammar")}
                    className="hover:bg-gray-100 px-3 py-2 rounded-md text font-medium"
                  >
                    Grammar AI
                  </button>
                </div>
                {showAuth ? (
                  <div className="flex items-center">
                    <button
                      onClick={() => setShowAuthModal(true)}
                      className="bg-blue-600 rounded-full text-white text-sm font-medium px-4 h-10 flex items-center hover:bg-blue-500 transition"
                    >
                    Login
                  </button>
                </div>
              ):(
            <div
            className={`sm:flex items-center ${
              isMenuOpen || width < 640 ? "hidden" : "flex"
            } items-center`}
          >
           {showUpgrade && width >= 1000 && (
              <button
                onClick={() => {
                  navigate("/pricing/");
                  toggleMenu();
                }}
                className="mr-5 bg-blue-600 rounded-full text-white text-sm font-medium px-4 h-10 flex items-center hover:bg-blue-500 transition"
              >
                Upgrade to Pro
              </button>
            )}

            <div className="relative">
              <div className="pr-8">
                <button  onClick={(e) => { e.stopPropagation(); setShowProfileDropDown(!showProfileDropDown); }}>
                  <FontAwesomeIcon icon={faUser} style={{ fontSize: "24px" }} />
                </button>
              </div>
              <ProfileDropDown 
                handleLogoutButton={handleLogoutButton} 
                showProfileDropDown={showProfileDropDown} 
                setShowProfileDropDown={setShowProfileDropDown} 
              />
            </div>
            <span className="">
              <button className="border-2 border-green-400 px-2.5 py-1 rounded-lg">
                <a
                  href="/pricing/"
                  className="font-semibold text-base text-green-400 hover:text-green-400 underline-offset-2 hover:underline "
                >
                  {tokens} Tokens
                </a>
              </button>
            </span>
          </div>
          )}
              </>
            )}
        </div>
      </nav>
    </>
  );
}
