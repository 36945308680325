const AnswerSubmitButton = ({ loadingState, onClick, userAnswer }) => {
  console.log("UserAnswer in answerSubmit: ", userAnswer)
  return (
    <div className="flex justify-center mt-24">
      <button 
      type="button" 
      className={`${!userAnswer ? "text-gray-500" : "text-white"} bg-blue-600 border-2 border-blue-600 py-2.5 px-24 rounded-lg font-medium text-lg transition-colors duration-300 ease-in-out
      hover:bg-blue-700 hover:border-blue-600
      active:bg-blue-800 active:border-blue-700 active:shadow-inner
      focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
      shadow-color-lg shadow-blue-400
      disabled:bg-gray-300 disabled:border-gray-300 disabled:shadow-gray-300 disabled:cursor-not-allowed disabled:text-gray-500`}
      onClick={onClick}
      disabled={Boolean(loadingState) || !userAnswer}
      >
        {loadingState ? "Loading..." : "Submit"}
      </button>
    </div>
  );

}
export default AnswerSubmitButton;