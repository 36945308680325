import { useEffect, useState, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import FlashcardEditField from "./FlashcardTermField";
import { ReactComponent as FlashcardIcon } from "../Assets/flashcard_icon.svg";
import { ReactComponent as WriteModeIcon } from "../Assets/write_mode_icon.svg";
import StartFlashcardSessionModal from "../components/DefinitionQuestionComponents/StartFlashcardSessionModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEllipsis,
  faPen,
  faArrowUpFromBracket,
  faKey,
  faUnlockAlt,
  faClone,
  faTrashAlt,
} from "@fortawesome/free-solid-svg-icons";
import api from "./../api";
import ConfirmDeletionModal from "../components/ConfirmDeletionModal";
import GetEditAccessModal from "../components/Modals/GetEditAccessModal";
import { notify } from "../providers/NotificationProvider";
import LimitModal from "../components/Modals/LimitModal";
import FlashcardCarousel from "../components/DefinitionQuestionComponents/FlashcardCarousel";

const FlashcardPreview = () => {
  const { uuid } = useParams();
  const navigate = useNavigate();

  const [performance, setPerformance] = useState(0);
  const [title, setTitle] = useState("");

  const [flashcards, setFlashcards] = useState([]);
  const [askMode, setAskMode] = useState("");
  const [showSessionModal, setShowSessionModal] = useState(false);

  const [flashcardModeQuestionFilter, setFlashcardModeQuestionFilter] = useState("");
  const [writeModeQustionFilter, setWriteModeQuestionFilter] = useState("");

  const [titleEditMode, setTitleEditMode] = useState(false);
  const [userEditAccess, setUserEditAccess] = useState(false);
  const [numberOfFlashcardsLimit, setNumberOfFlashcardsLimit] = useState(0);
  const [showLimitModal, setShowLimitModal] = useState(false);
  const [showConfirmDeletionModal, setShowConfirmDeletionModal] = useState(false);
  const [showDropdown, setShowDropdown] = useState(false);
  const [showGetEditAccessModal, setShowGetEditAccessModal] = useState(false);

  const titleInputRef = useRef(null);

  useEffect(() => {
    if (titleEditMode) {
      titleInputRef.current.style.width = `${title.length + 1}ch`;
    }
  }, [title, titleEditMode]);



  useEffect(() => {
    const urls = [
      `/api/flashcards/all/${uuid}/`,
      `/api/flashcards/flashcard_set/${uuid}/`,
      `/api/flashcards/user_flashcard_set/${uuid}/`,
      `/api/subscription/`,
      `/api/flashcards/learned_progress/${uuid}/`,
      `/api/flashcards/user_edit_access/${uuid}/`,
    ];

    Promise.all(urls.map((url) => api.get(url)))
      .then((responses) => {
        const flashcardResponse = Array.from(responses[0].data);
        const flashcardSetResponse = responses[1];
        const userFlashcardSetResponse = responses[2];
        const subscriptionResponse = responses[3];
        const learnedProgressResponse = responses[4];
        const userEditAccessResponse = responses[5];

        setFlashcards(flashcardResponse);
        setTitle(flashcardSetResponse.data.title);
        setWriteModeQuestionFilter(
          userFlashcardSetResponse.data.write_mode_question_type
        );
        setFlashcardModeQuestionFilter(
          userFlashcardSetResponse.data.flashcard_mode_question_type
        );
        setPerformance(learnedProgressResponse.data.performance);
        setUserEditAccess(userEditAccessResponse.data.user_edit_access);
        if (subscriptionResponse.plan === "max") {
          setNumberOfFlashcardsLimit(5000);
        } else {
          setNumberOfFlashcardsLimit(100);
        }
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  }, [uuid]);

  const handleScroll = () => {
    const position = window.scrollY;
    if (position > 0) {
      setShowDropdown(false);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleWriteMode = () => {
    navigate(`/flashcard/mode/write/${uuid}/${writeModeQustionFilter}/`);
  };

  const handleFlashcardMode = () => {
    navigate(`/flashcard/mode/flashcard/${uuid}/${flashcardModeQuestionFilter}/`);
  };

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
    } catch (err) {
      alert("Failed to copy text: ", err);
    }
  };

  const handleDeleteAll = () => {
    api.delete("/api/flashcards/delete_flashcard_set/" + uuid + "/")
      .then((response) => {
        console.log("this is the response from the backend", response.data);
        notify({ message: "Flashcard set deleted!", type: "error" });
        navigate("/flashcard/all/");
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleCopyFlashcardSet = () => {
    api.post("/api/flashcards/copy_flashcard_set/", { uuid: uuid })
      .then((response) => {
        setShowDropdown(false);
        navigate(`/flashcard/preview/${response.data.new_set_uuid}/`);
        notify({ message: "Flashcard set copy created", type: "success" });
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleShareEditPassword = () => {
    api.get("/api/flashcards/flashcard_edit_password/" + uuid + "/")
      .then((response) => {
        console.log("Response: ", response.data);
        copyToClipboard(response.data.password);
        notify({ message: "Password saved to clipboard", type: "success" });
        setShowDropdown(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  const handleShareFlashcardSet = () => {
    copyToClipboard(`https://studybuddyai.me/share-flashcard-set/${uuid}/`);
    notify({ message: "Link to the set copied.", type: "success" });
    setShowDropdown(false);
  };

  const handleSaveEditTitle = () => {
    console.log("this is the title", title);
    console.log("this is the uuid", uuid);
    api.put("/api/flashcards/edit_flashcard_set_title/", {
        uuid: uuid,
        title: title,
      })
      .then((response) => {
        console.log("this is the response from the backend", response.data);
        setTitleEditMode(false);
      })
      .catch((error) => {
        console.error("There was an error!", error);
      });
  };

  return (
    <div className="min-h-screen bg-gray-50">
      <StartFlashcardSessionModal
        showModal={showSessionModal}
        setShowModal={setShowSessionModal}
        flashcards={flashcards}
        askMode={askMode}
      />

      <ConfirmDeletionModal
        showModal={showConfirmDeletionModal}
        setShowModal={setShowConfirmDeletionModal}
        handleDelete={handleDeleteAll}
        header={"DELETE FLASHCARD SET?"}
        text={
          "Are you sure you want to DELETE this flashcard set? This action cannot be undone."
        }
        buttonLabel={"DELETE SET"}
      />

      <GetEditAccessModal
        showModal={showGetEditAccessModal}
        setShowModal={setShowGetEditAccessModal}
        flashcardSetId={uuid}
      />

      <LimitModal
        showModal={showLimitModal}
        setShowModal={setShowLimitModal}
        limitReason={"flashcards"}
      />

      <div className="max-w-[800px] flex flex-col justify-center mx-auto">
        <div className="flex justify-between items-center text-2xl md:text-3xl lg:text-4xl font-semibold mt-4">
          {titleEditMode ? (
            <input
              ref={titleInputRef}
              type="text"
              value={title}
              onChange={(e) => {
                setTitle(e.target.value);
                titleInputRef.current.style.width = `${
                  e.target.value.length + 1
                }ch`;
              }}
              onKeyDown={(e) => {
                if (e.key === "Enter") {
                  titleInputRef.current.blur();
                }
              }}
              onBlur={handleSaveEditTitle}
              className="text-2xl md:text-3xl lg:text-4xl font-semibold outline-none border-b-2 border-transparent focus:border-gray-300 px-2.5 py-2 rounded-xl shadow"
              autoFocus
            />
          ) : (
            <span
              onClick={() => setTitleEditMode(true)}
              className=" hover:bg-gray-200 p-1.5 rounded-xl"
            >
              {title}
            </span>
          )}
          <div className="flex items-center space-x-2">
            <button
              onClick={handleShareFlashcardSet}
              className="text-gray-500 hover:text-gray-700 flex items-center px-4 py-2 rounded-lg transition duration-300 ease-in-out hover:bg-gray-200"
            >
              <FontAwesomeIcon
                icon={faArrowUpFromBracket}
                size="1x"
                className="text-xl mr-2"
              />
              <span className="text-lg">Share</span>
            </button>

            <div className="relative">
              <button
                className="flex items-center"
                onClick={() => setShowDropdown((prev) => !prev)}
              >
                <FontAwesomeIcon
                  icon={faEllipsis}
                  className="text-3xl text-gray-500 hover:text-gray-700"
                />
              </button>

              {showDropdown && (
                <div className="absolute top-full right-0 mt-2 bg-white rounded-lg shadow w-60 z-50">
                  <ul
                    className="py-2 text-gray-700 text-base"
                    aria-labelledby="dropdownMenuIconHorizontalButton"
                  >
                    <li>
                      <button
                        className="flex items-center w-full text-left px-4 py-2 hover:bg-gray-200 focus:outline-none"
                        onClick={handleCopyFlashcardSet}
                      >
                        <FontAwesomeIcon icon={faClone} className="mr-4" />
                        Create a Copy
                      </button>
                    </li>
                    {!userEditAccess && (
                      <li>
                        <button
                          type="button"
                          className="flex items-center w-full text-left px-4 py-2 hover:bg-gray-200 focus:outline-none"
                          onClick={() => {
                            setShowDropdown(false);
                            setShowGetEditAccessModal(true);
                          }}
                        >
                          <FontAwesomeIcon
                            icon={faUnlockAlt}
                            className="mr-4"
                          />
                          Get Access to Edit
                        </button>
                      </li>
                    )}
                    {true && (
                      <>
                        <li>
                          <button
                            type="button"
                            className="flex items-center w-full text-left px-4 py-2 hover:bg-gray-200 focus:outline-none text-base"
                            onClick={handleShareEditPassword}
                          >
                            <FontAwesomeIcon icon={faKey} className="mr-4" />
                            Share Edit Password
                          </button>
                        </li>
                        <div className="border-t border-gray-200 my-1.5" />
                        <button
                          type="button"
                          className="flex items-center w-full text-left px-4 py-2 text-base text-red-600 hover:text-white hover:bg-red-600 focus:outline-none"
                          onClick={() => {
                            setShowDropdown(false);
                            setShowConfirmDeletionModal(true);
                          }}
                        >
                          <FontAwesomeIcon icon={faTrashAlt} className="mr-4" />
                          Delete Set
                        </button>
                      </>
                    )}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>


        <FlashcardCarousel flashcards={flashcards} />


          <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between w-full">
            <div className="bg-white w-1/2 rounded-lg shadow">
              <button
                className="flex items-center space-x-2 border-2 border-gray-300 py-3 px-10 rounded-lg hover:bg-blue-100 hover:border-blue-500
              active:bg-blue-100 active:border-blue-500 font-medium w-full justify-center sm:justify-start"
                onClick={handleFlashcardMode}
              >
                <FlashcardIcon
                  fill="#2463eb"
                  style={{ height: "30px", width: "auto" }}
                />
                <span className="text-lg font-semibold">Flashcard Mode</span>
              </button>
            </div>
            <div className="bg-white w-1/2 rounded-lg shadow">
              <button
                className="flex items-center space-x-2 border-2 border-gray-300 py-3 px-10 rounded-lg hover:bg-blue-100 hover:border-blue-500
              active:bg-blue-100 active:border-blue-500 font-medium w-full justify-center sm:justify-start"
                onClick={handleWriteMode}
              >
                <WriteModeIcon
                  fill="#2463eb"
                  style={{ height: "30px", width: "auto" }}
                />
                <span className="text-lg font-semibold">Write Mode</span>
              </button>
            </div>
          </div>

          <div className="bg-white rounded-2xl shadow p-4 mt-12">
            <div>
              <h1 className="text-2xl font-semibold mb-3">Performance</h1>
              <div className="text-xl font-medium mb-2">
                You have learned {Math.round(performance)}% of all the terms
              </div>
              <div className="w-full h-5 mb-4 bg-gray-200 rounded-full">
                <div
                  className={`bg-green-400 h-5 rounded-full w-[${performance}]`}
                  style={{ width: `${performance}%` }}
                ></div>
              </div>
            </div>
          </div>

          <div className="mt-10 flex justify-between items-center pb-2 mb-4 border-b-2 border-gray-200">
            <h2 className="text-2xl font-semibold">
              Terms in this set ({flashcards.length})
            </h2>

            <button
              onClick={() => navigate(`/flashcard/edit/${uuid}/`)}
              className="text-blue-600 hover:text-blue-700 flex items-center px-3 py-1 rounded-lg transition duration-300 ease-in-out hover:bg-blue-200"
            >
              <FontAwesomeIcon icon={faPen} size="1x" className="text-lg mr-2" />
              <span className="text-lg font-medium">Edit set</span>
            </button>
          </div>

          <FlashcardEditField
            setFlashcards={setFlashcards}
            flashcards={flashcards}
            flashcardSetUuid={uuid}
            flashcardLimit={numberOfFlashcardsLimit}
            setShowLimitModal={setShowLimitModal}
          />
      </div>
    </div>
  );
};

export default FlashcardPreview;

//FlashcardPreview Before

// import { useEffect, useState } from "react";
// import { useParams, useNavigate } from "react-router-dom";
// import FlashcardEditField from "./FlashcardTermField";
// import FlashcardComponent from "../components/DefinitionQuestionComponents/FlashcardComponent";
// import { ReactComponent as FlashcardIcon } from "../Assets/flashcard_icon.svg";
// import { ReactComponent as WriteModeIcon } from "../Assets/write_mode_icon.svg";
// import StartFlashcardSessionModal from "../components/DefinitionQuestionComponents/StartFlashcardSessionModal";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { faTrash } from "@fortawesome/free-solid-svg-icons";
// import api from "./../api";

// const FlashcardPreview = () => {
//   const { uuid } = useParams();
//   const navigate = useNavigate();

//   const [performance, setPerformance] = useState(0);
//   const [title, setTitle] = useState("");

//   const [flashcards, setFlashcards] = useState([]);
//   const [flashcardIndex, setFlashcardIndex] = useState(0);
//   const [displayedFlashcard, setDisplayedFlashcard] = useState([]);
//   const [askMode, setAskMode] = useState("");
//   const [showSessionModal, setShowSessionModal] = useState(false);

//   const createDisplayFlashcard = (flashcardData) => {
//     const displayFlashcard = flashcardData.map((item) => {
//       return {
//         front: item.word,
//         back: item.definition,
//       };
//     });
//     return displayFlashcard;
//   };

//   useEffect(() => {
//     api.get("/api/flashcards/all/"+uuid+"/")
//     .then((response) => {
//       console.log("this is the response from the backend", response.data);
//       const flashcardData = Array.from(response.data);
//       setFlashcards(flashcardData);
//       setDisplayedFlashcard(createDisplayFlashcard(flashcardData));
//       const numberOfCorrect = flashcardData.reduce((acc, item) => {
//         return acc + (item.correct_incorrect.length > 0 && item.correct_incorrect[item.correct_incorrect.length - 1] === true ? 1 : 0);
//       }, 0);

//       setPerformance((numberOfCorrect / flashcardData.length) * 100);

//     })
//     .catch((error) => {
//       console.error("There was an error!", error);
//     });
//     api.get("/api/flashcards/flashcard_set/"+uuid+"/")
//     .then((response) => {
//       console.log("this is the response from the backend", response.data);
//       setTitle(response.data.title);
//     })
//     .catch((error) => {
//       console.error("There was an error!", error);
//     });
//   }, []);

//   const handleWriteMode = () => {
//     api.get("/api/flashcards/question_index/"+uuid+"/")
//     .then((response) => {
//       console.log("this is the response from the backend", response.data);
//       if (response.data.question_index > 0) {
//         navigate(`/flashcard/mode/write/${uuid}/${response.data.question_type}/`);
//       }
//       else {
//         setShowSessionModal(true);
//         setAskMode("write");
//       }
//     })
//     .catch((error) => {
//       console.error("There was an error!", error);
//     });
//   };

//   const handleFlashcardMode = () => {
//     api.get("/api/flashcards/flashcard_mode/question_index/"+uuid+"/")
//     .then((response) => {
//       console.log("this is the response from the backend", response.data);
//       if (response.data.question_index > 0) {
//         navigate(`/flashcard/mode/flashcard/${uuid}/${response.data.question_type}/`);
//       }
//       else {
//         setAskMode("flashcard");
//         setShowSessionModal(true);
//       }
//     })
//     .catch((error) => {
//       console.error("There was an error!", error);
//     });
//   };

//   const handleDeleteAll = () => {
//     api.delete("/api/flashcards/delete_flashcard_set/"+uuid+"/")
//     .then((response) => {
//       console.log("this is the response from the backend", response.data);
//         navigate("/")
//     })
//     .catch((error) => {
//       console.error("There was an error!", error);
//     });
//   };

//   return (
//     <div className="px-5 min-h-screen" >
//       <StartFlashcardSessionModal showModal={showSessionModal} setShowModal={setShowSessionModal} flashcards={flashcards} askMode={askMode} />
//       <div className="max-w-[800px] flex flex-col justify-center mx-auto">
//       <div className="flex justify-between items-center text-2xl md:text-3xl font-semibold mt-4 mb-3">
//         <span>{title}</span>
//         <button onClick={handleDeleteAll} className="text-red-500 hover:text-red-600 flex items-center px-4 py-2 rounded-lg transition duration-300 ease-in-out hover:bg-red-100">
//             <FontAwesomeIcon icon={faTrash} size="1x" className="text-lg mr-2" />
//             <span className="text-base">Delete All</span>
//         </button>
//       </div>

//         <div className="mt-2 mb-24">
//           <FlashcardComponent cards={displayedFlashcard} cardIndex={flashcardIndex} setCardIndex={setFlashcardIndex} askMode={"preview"} />
//         </div>
//         <div className="flex flex-col sm:flex-row gap-2 sm:gap-0 justify-between w-full">
//           <button className="flex items-center space-x-2 border-2 border-gray-300 py-3 px-10 rounded-lg hover:bg-blue-100 hover:border-blue-500
//            active:bg-blue-100 active:border-blue-500 font-medium w-full justify-center sm:justify-start" onClick={handleFlashcardMode}>
//             <FlashcardIcon fill="#2463eb" style={{ height: "30px", width: "auto" }} />
//             <span className="text-lg font-semibold">Flashcard Mode</span>
//           </button>
//           <button className="flex items-center space-x-2 border-2 border-gray-300 py-3 px-10 rounded-lg hover:bg-blue-100 hover:border-blue-500
//           active:bg-blue-100 active:border-blue-500 font-medium w-full justify-center sm:justify-start" onClick={handleWriteMode}>
//             <WriteModeIcon fill="#2463eb" style={{ height: "30px", width: "auto" }} />
//             <span className="text-lg font-semibold">Write Mode</span>
//           </button>
//         </div>

//         <div className="bg-white rounded-2xl shadow p-4 mt-12">
//           <div>
//             <h1 className="text-2xl font-semibold mb-3">Performance</h1>
//             <div className="text-xl font-medium mb-2">
//               You have learned {Math.round(performance)}% of all the words
//             </div>
//             <div className="w-full h-5 mb-4 bg-green-200 rounded-full">
//               <div
//                 className={`bg-green-400 h-5 rounded-full w-[${performance}]`}
//                 style={{ width: `${performance}%` }}
//               ></div>
//             </div>
//           </div>

//         </div>

//         <div className="text-2xl font-medium mt-12 mb-4">
//           <span>Terms in this set ({flashcards.length})</span>
//         </div>
//         <div>
//           <FlashcardEditField setFlashcards={setFlashcards} flashcards={flashcards} flashcardSetUuid={uuid} />
//         </div>
//       </div>
//     </div>
//   );
// };

// export default FlashcardPreview;
