import { useEffect, useRef } from 'react';

const AnswerInputFieldIncorrectCorrection = ({ userAnswer, correction, correctionHeader }) => {
  const userAnswerRef = useRef(null);
  const correctionRef = useRef(null);

  useEffect(() => {
    handleAutoResize(userAnswerRef.current);
    handleAutoResize(correctionRef.current);
  }, [userAnswer, correction]);

  const handleAutoResize = (element) => {
    if (element) {
      element.style.height = 'auto'; 

      if (element.scrollHeight > element.clientHeight) {
        element.style.height = `${element.scrollHeight}px`;
        element.style.overflowY = 'auto';
      } else {
        element.style.overflowY = 'hidden';
      }
    }
  };



  const textareaStyle = {
    maxHeight: '12em',
    resize: 'none',
    whiteSpace: 'pre-wrap',
    wordWrap: 'break-word',
    overflowY: 'hidden',
    scrollbarWidth: 'none',
    msOverflowStyle: 'none',
  };

  const textareaWebkitScrollbarStyle = {
    display: 'none',
  };

  return (
    <div className="flex flex-col items-center">
      <p className="font-medium text-xl mb-3">Your answer</p>
      <textarea
        readOnly
        value={userAnswer}
        style={{
          ...textareaStyle,
          '::-webkit-scrollbar': textareaWebkitScrollbarStyle,
        }}
        ref={userAnswerRef}
        rows={1}
        wrap="soft"
        className={"mb-8 p-2.5 w-full border-2 border-red-400 bg-red-50 rounded-lg shadow-color-lg shadow-red-300 outline-none font-medium"}
      />
      <p className="font-medium text-xl mb-3">{correctionHeader}</p>
      <textarea
        readOnly
        value={correction}
        style={{
          ...textareaStyle,
          '::-webkit-scrollbar': textareaWebkitScrollbarStyle,
        }}
        ref={correctionRef}
        rows={1}
        wrap="soft"
        className="p-2.5 w-full border-2 border-green-400 bg-green-50 rounded-lg shadow-color-lg shadow-green-300 outline-none"
      />
    </div>
  );
};

export default AnswerInputFieldIncorrectCorrection;
