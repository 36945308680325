import { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faPaste } from '@fortawesome/free-solid-svg-icons';


const ExamUploadText = ({ userCharacterLimit, text, setText }) => {
  const [textLength, setTextLength] = useState(0);
  const [error, setError] = useState("");

  const onTextChange = (e) => {
    const { value } = e.target;
    setTextLength(value.length);
    if (textLength <= userCharacterLimit) {
      setText(value);
    }
  };

  const handleClipboardPaste = () => {
    navigator.clipboard.readText().then(
      clipText => {
        setText(prev => prev +clipText);
        setTextLength(prev => prev + clipText.length);
      }
    );
  };



  return (
    <div className="">


      {/* <button className='mb-2 flex flex-row justify-end w-full pr-2' onClick={handleClipboardPaste}>
        <FontAwesomeIcon icon={faPaste} className='text-xl text-blue-600 hover:text-blue-700' /> 
      </button> */}

      <textarea 
        value={text}
        type="text" 
        className="w-full p-2.5 bord2 shadow border-gray-200 rounded-md resize-none outline-none border-2 focus:border-gray-400" placeholder="Paste your text here..." 
        onChange={(e) => onTextChange(e)}
        rows={15}
        cols={50}
      />
      <p className={`font-medium mt-2.5 text-lg ${textLength > userCharacterLimit && "text-red-500"}`}>Character Count: {textLength.toLocaleString()}/{userCharacterLimit.toLocaleString()}</p>
      <p className='text-lg text-red-500 font-medium mt-2'>{error}</p>
    </div>
  );
};

export default ExamUploadText;