import React, { useEffect, useState } from 'react';

const WordPressPage = () => {
  const [pageContent, setPageContent] = useState('');

  useEffect(() => {
    const fetchPage = async () => {
      try {
        const response = await fetch('https://studdybuddy.roistars.tech/?page_id=591');
        const html = await response.text();
        setPageContent(html);
      } catch (error) {
        console.error('Error fetching page:', error);
      }
    };

    fetchPage();
  }, []);

  useEffect(() => {
    const existingScript = document.querySelector('script[src="path/to/your/script.js"]');
    if (!existingScript) {
        const script = document.createElement('script');
        script.src = "path/to/your/script.js";
        script.onload = () => console.log('Script loaded successfully');
        document.head.appendChild(script);
    } else {
        console.log('Script already loaded');
    }
}, []);



  if (!pageContent) return <div>Loading...</div>;

  return (
    <div dangerouslySetInnerHTML={{ __html: pageContent }} />
  );
};

export default WordPressPage;




//This is the old code for the page:
// import React, { useEffect, useState } from 'react';

// const WordPressPage = () => {
//   const [pageContent, setPageContent] = useState(null);
//   const [error, setError] = useState(null);

//   useEffect(() => {
//     const fetchPageContent = async () => {
//       try {
//         const response = await fetch('https://studdybuddy.roistars.tech/wp-json/wp/v2/pages/591');
//         if (!response.ok) {
//           throw new Error('Network response was not ok');
//         }
//         const data = await response.json();
//         setPageContent(data.content.rendered);
//       } catch (error) {
//         setError(error.toString());
//       }
//     };
  
//     fetchPageContent();
//   }, []);
  

//   if (error) {
//     return <div>Error: {error}</div>;
//   }

//   if (!pageContent) {
//     return <div>Loading...</div>;
//   }

//   return (
//     <div>
//       {/* Display the page content */}
//       <div dangerouslySetInnerHTML={{ __html: pageContent }} />
//     </div>
//   );
// };

// export default WordPressPage;
