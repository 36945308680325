import { useParams, useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import useAxiosGet from "../hooks/useAxiosGet";
import PointsBarComponent from "../components/QuestionRecap/PointsBarComponent";
import OptionsCorrectionComponent from "../components/SummaryQuestionComponents/OptionsCorrectionComponent";
import api from "../api";
import LoadingSpinner from "../components/General/LoadingSpinner";
import AnswerInputFieldCorrectCorrection from "../components/General/AnswerInputFieldCorrectCorrection";
import AnswerInputFieldIncorrectCorrection from "../components/General/AnswerInputFieldIncorrectCorrection";


const ExamRecap = () => {
  const [userAnswers, setUserAnswers] = useState([]);
  const [questions, setQuestions] = useState([]);
  const [corrections, setCorrections] = useState([]);
  const [correctIncorrect, setCorrectIncorrect] = useState([]);
  const [pointsList, setPointsList] = useState([]);
  const [maxPoints, setMaxPoints] = useState(0);
  const [points, setPoints] = useState(0);


  const { uuid } = useParams();
  const navigate = useNavigate();

  const { data, loading, error } = useAxiosGet("/api/summary/" + uuid + "/");

  const getMaxPointsForQuestionType = (type) => {
    if (type === "multipleChoice") {
      return 1;
    } else if (type === "trueOrFalse") {
      return 1;
    } else if (type === "fillInTheBlank") {
      return 2;
    } else if (type === "shortAnswer") {
      return 5;
    } else if (type === "essay") {
      return 10;
    }
  };



  useEffect(() => {
    if (data) {
      setUserAnswers(eval(data.user_answers));
      setCorrections(eval(data.corrections));
      setCorrectIncorrect(eval(data.correct_incorrect));
      let totalPointsCalc = 0;
      eval(data.questions).forEach((question) => {
        totalPointsCalc += getMaxPointsForQuestionType(question.type);
      });
      setMaxPoints(totalPointsCalc);
      
      setPointsList(eval(data.points_list));
      setPoints(eval(data.points_list).reduce((a, b) => a + b, 0));
      setQuestions(eval(data.questions));
      console.log("these are the quesitons", questions);
      console.log(data);
    }
    if (loading) {
      console.log("Loading");
    }
    if (error) {
      console.log(error);
    }
  }, [data, loading, error]);

  console.log("this is the max points", maxPoints)
  console.log("this is the student points", points)
  console.log("this is the pointsList", pointsList)


  const getChoiceIndex = (choices, userAnswer) => {
    console.log("this is the user answer", userAnswer);
    console.log("choices", choices.indexOf(userAnswer));
    return choices.indexOf(userAnswer);
  };


  const handleRepeatButtonClicked = () => {
    api.put(`/api/delete_summary_fields/${uuid}/`, {})
    .then(() => {
      console.log("Deleted fields");
      navigate("/exam/questions/"+uuid+"/");
    })
    .catch((error) => {
      console.log("Error while deleting fields:", error);
    });
  };

  if (loading || questions.length === 0) {
    return (
      <div className="flex justify-center items-center h-screen">
        <LoadingSpinner />
      </div>
    );
  }
  return (
    <div style={{ backgroundColor: "#F6F7FB" }} className="px-6 min-h-screen">
      <PointsBarComponent points={points} maxPoints={maxPoints} handleExitButtonClicked={() => navigate("/")} handleRepeatButtonClicked={handleRepeatButtonClicked} />
      {questions.map((question, index) => (
        <div className="flex justify-center items-center" key={index}>
          <div 
            className={`max-w-[735px] w-full bg-white rounded-2xl mb-8 p-8 shadow-color-lg ${correctIncorrect[index] ? "shadow-green-300" : "shadow-red-300"}`}
          >
            <p className="text-xl mb-4">{question.question}</p>
            <p className="text-lg text-neutral-700 mb-4 font-medium">Points: {pointsList[index]}</p>
            {question.type === "multipleChoice" && (
              <div>
                {correctIncorrect[index] ? (
                  <div>
                    <OptionsCorrectionComponent
                      optionsList={question.choices}
                      correctIndex={getChoiceIndex(
                        question.choices,
                        userAnswers[index]
                      )}
                    />
                  </div>
                ) : (
                  <div>
                    <OptionsCorrectionComponent
                      optionsList={question.choices}
                      correctIndex={corrections[index]}
                      incorrectIndex={getChoiceIndex(
                        question.choices,
                        userAnswers[index]
                      )}
                    />
                  </div>
                )}
              </div>
            )}
            {question.type === "trueOrFalse" && (
              <div>
                {correctIncorrect[index] ? (
                  <div>
                    <OptionsCorrectionComponent
                      optionsList={["True", "False"]}
                      correctIndex={getChoiceIndex(
                        ["True", "False"],
                        userAnswers[index]
                      )}
                    />
                  </div>
                ) : (
                  <div>
                    <OptionsCorrectionComponent
                      optionsList={["True", "False"]}
                      correctIndex={corrections[index]}
                      incorrectIndex={getChoiceIndex(
                        ["True", "False"],
                        userAnswers[index]
                      )}
                    />
                  </div>
                )}
              </div>
            )}
            {question.type === "fillInTheBlank" && (
              <>
                {correctIncorrect[index] ? (
                  <AnswerInputFieldCorrectCorrection
                    userAnswer={userAnswers[index]}
                    showHeader={true}
                  />
                ) : (
                  <AnswerInputFieldIncorrectCorrection
                    correction={corrections[index]}
                    userAnswer={userAnswers[index]}
                    correctionHeader={"This would be a word that fits in the blank"}
                  />
                )}
              </>
            )}

            {question.type === "shortAnswer" && (
              <>
                {correctIncorrect[index] ? (
                  <AnswerInputFieldCorrectCorrection
                    userAnswer={userAnswers[index]}
                    showHeader={true}
                  />
                ) : (
                  <AnswerInputFieldIncorrectCorrection
                    correction={corrections[index]}
                    userAnswer={userAnswers[index]}
                    correctionHeader={"This would be the correct answer"}
                  />
                )}
              </>
            )}
            {question.type === "essay" && (
              <>
                {correctIncorrect[index] ? (
                  <AnswerInputFieldCorrectCorrection
                    userAnswer={userAnswers[index]}
                    showHeader={true}
                  />
                ) : (
                  <AnswerInputFieldIncorrectCorrection
                    correction={corrections[index]}
                    userAnswer={userAnswers[index]}
                    correctionHeader={"Feedback on your essay"}
                  />
                )}
              </>
            )}
          </div>
        </div>

      ))}
      <div className="flex justify-center mt-12 pb-24">
        <button
          type="button"
          className="text-white bg-blue-600 border-2 border-blue-600 py-2.5 px-24 rounded-lg font-medium text-lg transition-colors duration-300 ease-in-out
          hover:bg-blue-700 hover:border-blue-600
          active:bg-blue-800 active:border-blue-700 active:shadow-inner
          focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50
          shadow-color-lg shadow-blue-400"
          onClick={handleRepeatButtonClicked}
          >
            Repeat exam
        </button>
      </div>

    </div>
  );
};
export default ExamRecap;